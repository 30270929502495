import { Children } from 'react';
import type { ComponentPropsWithRef, JSX } from 'react';

import { Box, Flex } from '@change-corgi/design-system/layout';

type Props = Omit<ComponentPropsWithRef<typeof Box>, 'variant'>;

/**
 * @doc $DOC:Breadcrumbs
 * Provides a secondary means of navigation
 */
export function Breadcrumbs({ children, ...rest }: Props): JSX.Element {
	const childrenArray = Children.toArray(children);

	return (
		<nav aria-label="breadcrumbs">
			<Box
				as="ol"
				sx={{
					color: 'typography-primary',
					textDecorationColor: 'typography-primary',
					listStyleType: 'none',
					m: 0,
					p: 0,
					li: {
						mr: 8,
					},
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'li[aria-current=page]': {
						mr: 0,
						textDecorationColor: 'primary-changeRed',
						color: 'primary-changeRed',
						fontWeight: 'bold',
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'[data-icon]': {
							display: 'none',
						},
					},
				}}
				{...rest}
			>
				{childrenArray.map((child, index) => (
					<Flex
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						as="li"
						sx={{
							alignItems: 'center',
							display: 'inline-flex',
							listStyleType: 'none',
							m: 0,
							p: 0,
						}}
						aria-current={index === childrenArray.length - 1 ? 'page' : undefined}
						{...rest}
					>
						{child}
					</Flex>
				))}
			</Box>
		</nav>
	);
}
