import { Children } from 'react';
import type { ComponentPropsWithRef, ForwardedRef, JSX, PropsWithoutRef } from 'react';

import styled from '@emotion/styled';
import { Button as ButtonBase } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import { Link } from '@change-corgi/core/react/router';
import { generateBreakpointAttributes, normalizeResponsiveValue } from '@change-corgi/design-system/theme';
import type { ComponentWithAs, PropsWithoutAs } from '@change-corgi/design-system/types';
import { Text } from '@change-corgi/design-system/typography';

import { ButtonWithIconWrapper } from './common/ButtonWithIconWrapper';
import type { ButtonProps } from './shared/types';

const DecoratedButton: ComponentWithAs<'button', ComponentPropsWithRef<typeof ButtonBase>> = styled(ButtonBase)`
	display: inline-flex;
	align-items: center;
	justify-content: center;
`;

export type Props = Omit<
	PropsWithoutAs<typeof Link, PropsWithoutRef<ButtonProps>>,
	keyof React.ComponentPropsWithoutRef<'button'>
> &
	React.ComponentPropsWithoutRef<'a'> & {
		onClick?: React.ComponentPropsWithoutRef<typeof Link>['onClick'];
	};

function ButtonLinkInner(
	{ children, variant, icon, iconPosition, mode, size, ...rest }: Props,
	ref: ForwardedRef<HTMLAnchorElement>,
): JSX.Element {
	const sizeResponsive = normalizeResponsiveValue(size);
	const modeResponsive = normalizeResponsiveValue(mode);
	if (!Children.toArray(children).length && process.env.NODE_ENV === 'development') {
		throw new Error('[a11y] missing label for ButtonLink');
	}
	return (
		<DecoratedButton
			variant={variant || 'primary'}
			as={Link}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
			ref={ref as any}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			{...(rest as any)}
			{...generateBreakpointAttributes('data-size', (idx) => sizeResponsive[idx] || 'medium')}
			{...generateBreakpointAttributes('data-mode', (idx) => {
				if (!Children.toArray(children).length) return 'icon';
				if (!icon) return 'label';
				return modeResponsive[idx] || 'icon+label';
			})}
		>
			{icon ? (
				<ButtonWithIconWrapper icon={icon} iconPosition={iconPosition}>
					{children}
				</ButtonWithIconWrapper>
			) : (
				<Text sx={{ fontSize: 'inherit', lineHeight: 'inherit' }} data-label>
					{children}
				</Text>
			)}
		</DecoratedButton>
	);
}

/**
 * For use cases where you want something that looks like a button and clicking it causes a navigation change
 *
 * @doc $DOC:ButtonLink
 */
export const ButtonLink = forwardRef(ButtonLinkInner);
