import { createContext } from '@change-corgi/core/react/context';

import type { useRadioGroup } from './useRadioGroup';

export type RadioGroupContextProps = ReturnType<typeof useRadioGroup>;

export const { Context, Provider, useContext } = createContext<
	RadioGroupContextProps | undefined,
	RadioGroupContextProps
>(undefined, {
	name: 'RadioGroupContext',
	processProviderProps: (props) => props,
});
