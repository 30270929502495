/*
 * This file is generated by icons/template.js
 */
const SvgReddit = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			d="M28.8 16.193a2.844 2.844 0 0 0-2.912-2.72 2.863 2.863 0 0 0-1.824.768c-2.176-1.504-4.768-2.304-7.424-2.368l1.248-5.984 4.128.832c.128 1.056 1.056 1.824 2.112 1.696 1.056-.128 1.824-1.056 1.696-2.112-.128-1.056-1.056-1.824-2.112-1.696a1.882 1.882 0 0 0-1.44.928l-4.704-.928a.59.59 0 0 0-.704.448l-1.408 6.688c-2.688.032-5.28.864-7.52 2.368-1.12-1.056-2.912-1.024-3.968.128-1.056 1.12-1.024 2.912.128 3.968.224.192.48.384.768.512a3.614 3.614 0 0 0 0 .832c0 4.32 5.024 7.84 11.232 7.84 6.208 0 11.232-3.488 11.232-7.84a3.614 3.614 0 0 0 0-.832c.896-.448 1.504-1.44 1.472-2.528ZM9.504 18.145c0-1.056.864-1.92 1.92-1.92s1.92.864 1.92 1.92-.864 1.92-1.92 1.92a1.926 1.926 0 0 1-1.92-1.92Zm11.2 5.312c-1.376 1.024-3.04 1.568-4.736 1.472-1.696.064-3.392-.448-4.736-1.472a.514.514 0 0 1 .064-.736c.192-.16.448-.16.672 0a6.368 6.368 0 0 0 4 1.216c1.44.064 2.848-.32 4.032-1.184a.584.584 0 0 1 .768 0 .584.584 0 0 1 0 .768v-.064h-.064Zm-.32-3.296a1.926 1.926 0 0 1-1.92-1.92c0-1.056.864-1.92 1.92-1.92s1.92.864 1.92 1.92a1.906 1.906 0 0 1-1.856 1.984h-.096l.032-.064Z"
		/>
	</svg>
);
SvgReddit.displayName = 'iconReddit';
export default SvgReddit;
