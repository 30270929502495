import type { ComponentPropsWithRef, JSX } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { VisuallyHidden } from '@change-corgi/design-system/a11y';
import { Link } from '@change-corgi/design-system/components/actions';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconArrowBack, iconArrowForward } from '@change-corgi/design-system/icons';
import type { IconComponent } from '@change-corgi/design-system/icons';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { buttonResetStyles } from '@change-corgi/design-system/reset';
import { Text } from '@change-corgi/design-system/typography';

import { DOTS, usePaginationRange } from './hooks/usePaginationRange';

type Props = Omit<ComponentPropsWithRef<typeof Box>, 'variant' | 'color' | 'backgroundColor'> & {
	totalItemCount: number;
	itemsPerPage: number;
	siblingCount?: number;
	currentPage: number;
	onPageChange?: (selectedPage: number) => void;
	generatePaginatedUrl: (selectedPage: number) => string;
};

/**
 * @doc $DOC:Pagination
 * Generates a numeric paginator based on the number of results total and the number displayed per page.
 * Ellipses character are displayed in order to truncate the list to a manageable size.
 * The number of page indicators displayed on either side of the current page indicator is the sibling count.
 * For example, if there are 10 pages and the current page is 5:
 * - 0 sibling count: 1 ... 5 ... 10
 * - 1 sibling count: 1 ... 4 5 6 ... 10
 * - etc.
 * The number page pages shown will remain constant as the user navigates in order to ensure a consistent layout.
 * This means you can see more than the sibling count in order to maintain the same number of elements.
 * In the example above with a current page of 2 and a sibling count of 1 more pages are shown to maintain the 7 items: 1 2 3 4 5 ... 10
 */
export function Pagination({
	totalItemCount,
	itemsPerPage,
	siblingCount = 1,
	currentPage,
	onPageChange,
	generatePaginatedUrl,
	...rest
}: Props): JSX.Element {
	const { translate } = useI18n();
	const paginationRange = usePaginationRange({ totalItemCount, itemsPerPage, siblingCount, currentPage });
	const previousPage = currentPage - 1;
	const nextPage = currentPage + 1;
	const lastPage = paginationRange[paginationRange.length - 1] as number; // last item cannot be "..."
	const isPreviousDisabled = currentPage === 1;
	const isNextDisabled = nextPage > lastPage;

	return (
		<Box {...rest} as="nav" role="navigation">
			<Flex
				as="ul"
				sx={{
					alignItems: 'center',
					listStyleType: 'none',
					m: 0,
					p: 0,
					gap: 32,
				}}
			>
				<li>
					<IconLink
						to={generatePaginatedUrl(previousPage)}
						label={translate('design-system.pagination.previous')}
						qaLabel="left-arrow"
						disabled={isPreviousDisabled}
						onClick={() => onPageChange?.(previousPage)}
						icon={iconArrowBack}
					/>
				</li>
				{paginationRange.map((pageNumber, index) => {
					if (pageNumber === currentPage) {
						return <CurrentPage key={pageNumber} pageNumber={pageNumber} />;
					}
					if (pageNumber === DOTS) {
						// eslint-disable-next-line react/no-array-index-key
						return <Ellipsis key={`${pageNumber}-${index}`} />;
					}
					return (
						<Box as="li" key={pageNumber} sx={{ py: 2 }}>
							<Link
								to={generatePaginatedUrl(pageNumber)}
								data-qa="page-link"
								onClick={() => onPageChange?.(pageNumber)}
								aria-label={translate('design-system.pagination.go-to-page', { pageNumber })}
								sx={{ ...buttonResetStyles, textDecoration: 'none' }}
							>
								<Text size="large" color="typography-primary">
									{pageNumber}
								</Text>
							</Link>
						</Box>
					);
				})}
				<li>
					<IconLink
						to={generatePaginatedUrl(nextPage)}
						label={translate('design-system.pagination.next')}
						qaLabel="right-arrow"
						disabled={isNextDisabled}
						onClick={() => onPageChange?.(nextPage)}
						icon={iconArrowForward}
					/>
				</li>
			</Flex>
		</Box>
	);
}

type PageProps = {
	pageNumber: number;
};

function CurrentPage({ pageNumber }: PageProps): JSX.Element {
	return (
		<Text
			as="li"
			aria-current
			size="large"
			color="typography-highlight"
			sx={{
				fontWeight: 'bold',
				borderBottomColor: 'typography-highlight',
				borderBottomWidth: 2,
				borderBottomStyle: 'solid',
				pt: 2,
			}}
		>
			{pageNumber}
		</Text>
	);
}

function Ellipsis(): JSX.Element {
	return (
		<Text as="li" size="large" color="typography-primary" py={2}>
			{DOTS}
		</Text>
	);
}

type IconLinkProps = Omit<ComponentPropsWithRef<typeof Link>, 'variant'> & {
	icon: IconComponent;
	label: string;
	qaLabel: string;
	disabled: boolean;
};

function IconLink({ icon, label, qaLabel, disabled, sx, ...rest }: IconLinkProps): JSX.Element {
	return (
		<Box
			variant="bordered"
			sx={{ borderRadius: 'full' }}
			color={disabled ? 'typography-disabled' : 'typography-primary'}
		>
			<Link
				title={label}
				data-qa={qaLabel}
				sx={{
					...buttonResetStyles,
					display: 'flex',
					padding: 4,
					pointerEvents: disabled ? 'none' : 'initial',
					...sx, // only required for storybook to take overrides into account
				}}
				{...rest}
			>
				<Icon icon={icon} size="xl" aria-hidden />
				<VisuallyHidden as="span">{label}</VisuallyHidden>
			</Link>
		</Box>
	);
}
