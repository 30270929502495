import type { JSX } from 'react';

import { Modal as ModalBase } from './Modal';
import type {
	Modal,
	ModalDecoratorProps,
	ModalFactoryOptions,
	ModalOptions,
	ModalOptionsDefault,
	ModalProps,
} from './shared/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function DefaultDecorator({ children }: ModalDecoratorProps<any, any>): JSX.Element {
	return <>{children}</>;
}

/**
 * @deprecated Prefer using createModalHook() instead of this, as it allows easier testing/mocking
 *
 * @doc $DOC:Modal
 */
export function createModal<OPTIONS extends ModalOptions = ModalOptionsDefault, RESULT = undefined, STATE = undefined>({
	name,
	decorator,
	...factoryProps
}: ModalFactoryOptions<OPTIONS, RESULT, STATE>): Modal<OPTIONS, RESULT, STATE> {
	const Decorator = decorator || DefaultDecorator;
	function Modal(props: ModalProps<OPTIONS, RESULT, STATE>): JSX.Element {
		const { options, persistentState } = props;
		return (
			<Decorator options={options} persistentState={persistentState}>
				<ModalBase<OPTIONS, RESULT, STATE> {...props} {...factoryProps} />
			</Decorator>
		);
	}
	Modal.displayName = name;
	return Modal;
}
