import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { VisuallyHidden } from '@change-corgi/design-system/a11y';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconClose } from '@change-corgi/design-system/icons';
import { Box } from '@change-corgi/design-system/layout';

import { closeButtonAttributes } from '../shared/consts';

type Props = {
	onClick: () => void;
};

export function ModalCloseButton({ onClick }: Props): JSX.Element {
	return (
		<Box
			as="button"
			data-testid="modal-close-button"
			data-qa="modal-close-button"
			onClick={onClick}
			sx={{
				color: 'neutral-grey700',
				backgroundColor: 'transparent',
				border: 'none',
				cursor: 'pointer',
				position: 'absolute',
				top: 12,
				right: 12,
				// non-conventional padding due to figma's icon size and necessary align with the default heading
				padding: 6,
				fontSize: 20,
				boxSizing: 'content-box',
				height: '1em',
				width: '1em',
			}}
			{...closeButtonAttributes}
		>
			<Icon sx={{ fontSize: 'inherit' }} icon={iconClose} />
			{/* using this approach for a11y for wider screen reader support
				https://www.sarasoueidan.com/blog/accessible-icon-buttons/ (technique #1) */}
			<VisuallyHidden>
				<Translate value="design-system.modal.close" />
			</VisuallyHidden>
		</Box>
	);
}
