import type { ThemeUIStyleObject } from 'theme-ui';

type BadgeVariant = 'neutral' | 'blue' | 'green' | 'purple' | 'red' | 'yellow';

export const badges: Record<BadgeVariant, ThemeUIStyleObject> = {
	neutral: {
		bg: 'neutral-grey100',
		color: 'typography-primary',
	},
	blue: {
		bg: 'status-info900',
		color: 'typography-lightPrimary',
	},
	green: {
		bg: 'status-positive500',
		color: 'typography-lightPrimary',
	},
	purple: {
		bg: 'status-info500',
		color: 'typography-lightPrimary',
	},
	red: {
		bg: 'status-critical500',
		color: 'typography-lightPrimary',
	},
	yellow: {
		bg: 'status-warning500',
		color: 'typography-lightPrimary',
	},
};
