/*
 * This file is generated by icons/template.js
 */
const SvgFacebookMessenger = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 27 24" {...props}>
		<g clipPath="url(#facebook-messenger_svg__a)">
			<path
				fill="currentColor"
				d="M13.5 0C6.406 0 .906 4.953.906 11.64c0 3.498 1.505 6.522 3.955 8.61a.93.93 0 0 1 .336.684l.07 2.136c.005.157.05.31.132.447a.982.982 0 0 0 .338.336 1.043 1.043 0 0 0 .943.066l2.5-1.05c.211-.09.45-.105.674-.048 1.15.3 2.37.462 3.646.462 7.093 0 12.594-4.953 12.594-11.64S20.594 0 13.5 0Z"
			/>
			<path
				fill="#fff"
				d="m5.937 15.045 3.7-5.592c.139-.21.322-.391.539-.53a1.98 1.98 0 0 1 1.492-.262c.254.056.493.163.702.312l2.944 2.103a.782.782 0 0 0 .91-.003l3.973-2.874c.529-.384 1.222.222.869.759l-3.703 5.589c-.139.21-.322.391-.539.53a1.98 1.98 0 0 1-1.492.262 1.934 1.934 0 0 1-.702-.312l-2.944-2.103a.78.78 0 0 0-.91.003l-3.973 2.874c-.529.384-1.221-.219-.866-.756Z"
			/>
		</g>
		<defs>
			<clipPath id="facebook-messenger_svg__a">
				<path fill="#fff" d="M.906 0h25.188v24H.906z" />
			</clipPath>
		</defs>
	</svg>
);
SvgFacebookMessenger.displayName = 'iconFacebookMessenger';
export default SvgFacebookMessenger;
