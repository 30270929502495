export function useRootAppInertToggle(rootAppSelector: string): {
	setInertOff: () => void;
	setInertOn: () => void;
} {
	const setInertOn = () => {
		const rootApp = document.querySelector(rootAppSelector);
		if (rootApp) {
			rootApp.setAttribute('inert', '');
			rootApp.setAttribute('aria-hidden', 'true');
		}
	};
	const setInertOff = () => {
		const rootApp = document.querySelector(rootAppSelector);
		if (rootApp) {
			rootApp?.removeAttribute('inert');
			rootApp?.removeAttribute('aria-hidden');
		}
	};
	return { setInertOn, setInertOff };
}
