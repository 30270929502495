import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Image } from '@change-corgi/design-system/components/media';
import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { CardBadge, type CardBadgeProps } from './CardBadge';

type BadgeType = CardBadgeProps['badgeType'];

type Props = Omit<CardBadgeProps, 'badgeType'> & {
	badgeType?: BadgeType;
	/**
	 * If undefined, the image loading state will be displayed. If empty string '', the default petition image will be displayed.
	 */
	imageUrl?: string;
	showVictory: boolean;
};

export type { Props as MediaProps };

export function Media({
	badgeType,
	dmName,
	imageUrl,
	promoterCount,
	promoterCountLocalized,
	promotersUrl,
	showVictory,
	supporterCount,
	supporterCountLocalized,
}: Props): JSX.Element {
	return (
		<Box
			sx={{
				position: 'relative',
				height: 190,
			}}
		>
			{typeof imageUrl === 'string' ? (
				<Image
					aspectRatio="16/10"
					fit="cover"
					sx={{
						width: '100%',
						height: '100%',
						// removes an unwanted padding under the image
						verticalAlign: 'top',
					}}
					src={imageUrl || 'https://static.change.org/images/default-petition-darker.gif'}
					alt=""
					loading="lazy"
					data-qa="petition-card-image"
				/>
			) : (
				<Flex
					sx={{
						alignItems: 'center',
						justifyContent: 'center',
						aspectRatio: '16/10' /* So the container fills the same space in the image case and loading spinner case */,
					}}
				>
					<Loader size="m" />
				</Flex>
			)}
			{badgeType && (
				<CardBadge
					badgeType={badgeType}
					dmName={dmName}
					promoterCount={promoterCount}
					promoterCountLocalized={promoterCountLocalized}
					promotersUrl={promotersUrl}
					supporterCount={supporterCount}
					supporterCountLocalized={supporterCountLocalized}
				/>
			)}
			{showVictory && (
				<Box
					backgroundColor="primary-changeRed"
					color="typography-lightPrimary"
					p="8"
					sx={{ borderBottomRightRadius: 'standard', position: 'absolute', left: 0, top: 0 }}
					data-qa="petition-card-victory-badge"
				>
					<Text>
						<Translate value="design-system.petition-card.victory-badge-label" />
					</Text>
				</Box>
			)}
		</Box>
	);
}
