import type { ComponentPropsWithRef, ForwardedRef, JSX } from 'react';
import { useEffect, useMemo, useState } from 'react';

import styled from '@emotion/styled';
import { alpha } from '@theme-ui/color';

import { forwardRef } from '@change-corgi/core/react/core';
import { Box } from '@change-corgi/design-system/layout';
import type { ColorName, ResponsiveValue } from '@change-corgi/design-system/theme';
import { derivedStyle, normalizeResponsiveValue } from '@change-corgi/design-system/theme';

export type Scale = 'xs' | 's' | 'm' | 'l' | 'xl';

type Props = Omit<ComponentPropsWithRef<typeof Box>, 'variant' | 'backgroundColor' | 'color'> & {
	size?: ResponsiveValue<Scale | number>;
	color?: ColorName;
};

export const SCALE: Record<Scale, number> = {
	xs: 32,
	s: 40,
	m: 48,
	l: 56,
	xl: 88,
};

const StyledOuter = styled(Box)`
	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}

	animation: spin 2s linear infinite;
`;

function StyledInner({ color }: Pick<Props, 'color'>) {
	return (
		<Box
			sx={{
				height: '100%',
				width: '100%',
				background: derivedStyle(
					(theme) =>
						`linear-gradient(${[
							alpha(color, 1)(theme),
							alpha(color, 0.5)(theme),
							alpha(color, 0.2)(theme),
							'rgba(0, 0, 0, 0)',
							'rgba(0, 0, 0, 0)',
						].join(',')})`,
				),
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'@supports (background: conic-gradient(red, red))': {
					background: derivedStyle(
						(theme) =>
							`conic-gradient(${[
								alpha(color, 1)(theme),
								alpha(color, 0.5)(theme),
								alpha(color, 0.2)(theme),
								'rgba(0, 0, 0, 0)',
								'rgba(0, 0, 0, 0)',
							].join(',')})`,
					),
				},
			}}
		/>
	);
}

function toSxSize(size: Scale | number | null | undefined) {
	if (size === undefined) return SCALE.m;
	return typeof size === 'string' ? SCALE[size] : size;
}

let idCpt = 0;

function LoaderInner({ size, color, sx, ...rest }: Props, ref: ForwardedRef<HTMLDivElement>): JSX.Element {
	const sxSize = useMemo(() => {
		if (!Array.isArray(size)) return toSxSize(size);
		const sizeResponsive = normalizeResponsiveValue(size);
		return sizeResponsive.map(toSxSize);
	}, [size]);

	// temporary solution to ensure id unicity at least client-side
	// otherwise a hidden Loader could impact any non-hidden loader
	// and the latter would be rendered with an invalid clip-path
	// TODO: find a better way to render this loader, or to generate a unique id
	const [clipPathId, setClipPathId] = useState('loader-clipPath');
	useEffect(() => {
		setClipPathId(`loader-clipPath-${idCpt}`);
		idCpt += 1;
	}, []);

	return (
		<StyledOuter
			ref={ref}
			sx={{
				size: sxSize,
				...sx, // only required for storybook to take overrides into account
			}}
			{...rest}
		>
			<svg viewBox="0 0 200 200">
				<clipPath id={clipPathId}>
					<path d="M100 100m-99,0a99,99,0 1,0 198,0a 99,99 0 1,0 -198,0zM100 100m-71,0a71,71,0 0,1 142,0a 71,71 0 0,1 -142,0z" />
				</clipPath>

				<foreignObject x="0" y="0" width="200" height="200" clipPath={`url(#${clipPathId})`}>
					<StyledInner color={color || 'typography-secondary'} />
				</foreignObject>
			</svg>
		</StyledOuter>
	);
}

/**
 * @doc $DOC:Loader
 */
export const Loader = forwardRef(LoaderInner);
