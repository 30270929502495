import type { ChangeEvent, ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';

import { BooleanInput } from '../components';

import { useContext as useRadioGroup } from './hooks/context';

type Props = Omit<ComponentPropsWithRef<typeof BooleanInput>, 'type'>;

function RadioInner({ name, value, onChange, ...rest }: Props, ref: ForwardedRef<HTMLInputElement>): JSX.Element {
	const radioGroupContext = useRadioGroup();

	const contextProps = radioGroupContext
		? {
				name: name ?? radioGroupContext.name,
				checked: radioGroupContext.value === value,
				onChange: (event: ChangeEvent<HTMLInputElement>) => {
					radioGroupContext.onChange?.(event);
					onChange?.(event);
				},
			}
		: {};

	return <BooleanInput value={value} onChange={onChange} {...rest} {...contextProps} type="radio" ref={ref} />;
}

/**
 * @doc $DOC:Radio
 */
export const Radio = forwardRef(RadioInner);
