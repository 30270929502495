import type { ComponentPropsWithoutRef, JSX } from 'react';

import { stripHtmlTags } from '@change-corgi/core/html';
import { Translate } from '@change-corgi/core/react/i18n';
import { Link } from '@change-corgi/design-system/components/actions';
import { Heading, Text } from '@change-corgi/design-system/typography';

type Props = {
	heading: string;
	descriptionHtml?: string;
	descriptionLineClamp: number;
	onEllipsisClick?: ComponentPropsWithoutRef<typeof Link>['onClick'];
	onHeadingClick?: ComponentPropsWithoutRef<typeof Link>['onClick'];
	petitionUrl: string;
};

export function Body({
	descriptionHtml,
	descriptionLineClamp,
	heading,
	onEllipsisClick,
	onHeadingClick,
	petitionUrl,
}: Props): JSX.Element {
	return (
		<>
			<Link onClick={onHeadingClick} sx={{ color: 'typography-primary', textDecoration: 'none' }} to={petitionUrl}>
				<Heading as="div" size="h5" mb="8" lineClamp={2} breakWord>
					{heading}
				</Heading>
			</Link>
			{descriptionHtml && (
				<>
					<Text size="caption" color="typography-secondary" lineClamp={descriptionLineClamp} breakWord>
						{stripHtmlTags(descriptionHtml)}
					</Text>
					<Text size="caption">
						<Link onClick={onEllipsisClick} to={petitionUrl}>
							<Translate value="design-system.petition-card.read-more-link" />
						</Link>
					</Text>
				</>
			)}
		</>
	);
}
