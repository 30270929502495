import type { ThemeUIStyleObject } from 'theme-ui';

export type BoxVariant = 'default' | 'bordered';

const boxDefault: ThemeUIStyleObject = {};

export const layout: Record<'box' | `box_${BoxVariant}`, ThemeUIStyleObject> = {
	box: boxDefault,
	box_default: boxDefault,
	box_bordered: {
		borderRadius: 'standard',
		borderColor: 'neutral-grey400',
		borderStyle: 'solid',
		borderWidth: 1,
	},
};
