import { createMandatoryContext } from '@change-corgi/core/react/context';

import type { useAccordion } from './useAccordion';

export type AccordionContextProps = ReturnType<typeof useAccordion>;

export const { Context, Provider, useContext } = createMandatoryContext<AccordionContextProps, AccordionContextProps>(
	undefined,
	{
		name: 'AccordionContext',
		processProviderProps: (props) => props,
	},
);
