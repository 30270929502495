import { DefaultModal } from '../components/variants/DefaultModal';
import { FullPageModal } from '../components/variants/FullPageModal';

import type { ModalOptions, ModalOptionsDefault, ModalVariant, ModalVariantComponent } from './types';

const VARIANT_MAPPING: Record<ModalVariant, ModalVariantComponent> = {
	default: DefaultModal,
	fullPage: FullPageModal,
};

export function getModalVariant<
	OPTIONS extends ModalOptions = ModalOptionsDefault,
	RESULT = undefined,
	STATE = undefined,
>(variant: ModalVariant): ModalVariantComponent<OPTIONS, RESULT, STATE> {
	return VARIANT_MAPPING[variant] as unknown as ModalVariantComponent<OPTIONS, RESULT, STATE>;
}
