import type { ThemeUIStyleObject } from 'theme-ui';

import { theme } from './theme';

export type { Theme } from './theme';

export type { BoxVariant } from './box';
export type { ContainerVariant } from './container';
export type { FontVariant } from './fonts';
export type { FontWeight } from './fontWeights';
export type { ButtonVariant, ButtonSize } from './buttons';
export type { LinkVariant } from './links';
export type { TextVariant } from './text';
export type { HeadingVariant } from './heading';
export type { FormSize } from './forms';
export {
	getInputVerticalPadding,
	getInputBorderWidth,
	getInputFontSize,
	getInputLineHeight,
	getInputHeight,
} from './forms';
export type { ColorName } from './colors';
export type { ZIndiceName } from './zIndices';
export type {
	BreakpointsValue,
	ResponsiveValue,
	ResponsiveArrayValue,
	MandatoryResponsiveArrayValue,
	MandatoryResponsiveValue,
	BreakpointIndex,
	Breakpoints,
} from './breakpoints';
export { normalizeResponsiveValue, generateBreakpointAttributes } from './breakpoints';

export { derivedStyle, derivedGlobalStyle } from './utils/derivedStyle';

export { useTheme } from './hooks/useTheme';
export { useBreakpoints } from './hooks/useBreakpoints';
export { useColor } from './hooks/useColor';

export type DesignSystemStyleObject = ThemeUIStyleObject;

/**
 * For internal use only, do not use this.
 *
 * Rely on the theme values (e.g. color names), the hooks (e.g. useColor()) or the derivedStyle() function instead.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const __internal_theme__ = theme;
