import type { JSX } from 'react';

import { initialFocusAttributes } from '../shared/consts';
import type {
	CloseModalWithResultsFunction,
	ModalOptions,
	ModalOptionsDefault,
	ModalOptionsProps,
	ModalProps,
} from '../shared/types';

import { DefaultModalHeading } from './DefaultModalHeading';
import { ModalCloseButton } from './ModalCloseButton';

export type Props<OPTIONS extends ModalOptions = ModalOptionsDefault, RESULT = undefined, STATE = undefined> = Pick<
	ModalProps<OPTIONS, RESULT, STATE>,
	'options' | 'persistentState' | 'onPersistentStateUpdate'
> &
	Pick<ModalOptionsProps<OPTIONS, RESULT, STATE>, 'heading' | 'title' | 'closeButton'> & {
		modalZIndex: number;
		closeModal: CloseModalWithResultsFunction<RESULT>;
		onCloseButtonClick: () => void;
	};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export function ModalHeading<
	OPTIONS extends ModalOptions = ModalOptionsDefault,
	RESULT = undefined,
	STATE = undefined,
>({
	options,
	heading: Heading,
	title,
	closeModal,
	closeButton,
	modalZIndex,
	onCloseButtonClick,
	persistentState,
	onPersistentStateUpdate,
}: Props<OPTIONS, RESULT, STATE>): JSX.Element {
	return (
		<>
			{Heading ? (
				<Heading
					options={options}
					initialFocusAttributes={initialFocusAttributes}
					closeModal={closeModal}
					persistentState={persistentState}
					updatePersistentState={onPersistentStateUpdate || noop}
					modalZIndex={modalZIndex}
				/>
			) : (
				title && <DefaultModalHeading options={options} title={title} initialFocusAttributes={initialFocusAttributes} />
			)}
			{closeButton !== false && <ModalCloseButton onClick={onCloseButtonClick} />}
		</>
	);
}
