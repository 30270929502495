/*
 * This file is generated by icons/template.js
 */
const SvgChangeOrg = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M8.149 18.144a2.912 2.912 0 0 0 2.84-2.303c.003-.01.044-.199.058-.282a.573.573 0 0 1 .564-.457h4.15a.594.594 0 0 1 .415.181c.086.09.138.205.15.328l-.01.336c-.097 1.902-.743 3.429-1.773 4.567a6.922 6.922 0 0 1-2.102 1.561c-1.24.61-2.69.925-4.271.925C3.606 23 0 20.428 0 15.447V9.553C0 4.584 3.576 2.013 8.126 2h.027c4.515 0 8.098 2.517 8.176 7.392v.055a.525.525 0 0 1-.153.37.554.554 0 0 1-.12.085.597.597 0 0 1-.295.074h-4.143a.575.575 0 0 1-.282-.074.53.53 0 0 1-.278-.364l-.031-.15c-.04-.198-.089-.393-.146-.58a2.916 2.916 0 0 0-5.653.995v5.378c-.002 1.607 1.314 2.966 2.92 2.963ZM20.976 23c-1.66 0-2.913-1.364-2.913-3.022a2.846 2.846 0 0 1 2.876-2.837c1.66 0 2.913 1.289 2.913 2.837.001 1.62-1.105 3.022-2.876 3.022Z"
			clipRule="evenodd"
		/>
	</svg>
);
SvgChangeOrg.displayName = 'iconChangeOrg';
export default SvgChangeOrg;
