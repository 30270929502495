import styled from '@emotion/styled';

/**
 * used to add text for screen readers that will not appear visually on the page
 * @see https://www.a11yproject.com/posts/how-to-hide-content/
 */
export const VisuallyHidden = styled.div`
	display: block;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	width: 1px;
	padding: 0;
	border: 0;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
`;
