import type { ComponentProps, ForwardedRef, JSX, ReactNode } from 'react';

import { Textarea as TextareaBase } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import { generateBreakpointAttributes, normalizeResponsiveValue } from '@change-corgi/design-system/theme';
import type { FormSize, ResponsiveValue } from '@change-corgi/design-system/theme';

type Props = Omit<ComponentProps<typeof TextareaBase>, 'size'> & {
	error?: ReactNode;
	size?: ResponsiveValue<FormSize>;
};

function BareTextareaInner(
	{ error, variant, size, ...rest }: Props,
	ref: ForwardedRef<HTMLTextAreaElement>,
): JSX.Element {
	const sizeResponsive = normalizeResponsiveValue(size);
	return (
		<TextareaBase
			data-error={!!error}
			{...generateBreakpointAttributes('data-size', (idx) => sizeResponsive[idx] || 'default')}
			ref={ref}
			{...rest}
		/>
	);
}

/**
 * @doc $DOC:BareTextarea
 */
export const BareTextarea = forwardRef(BareTextareaInner);
