/*
 * This file is generated by icons/template.js
 */
const SvgInfoOutline = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
		<g clipPath="url(#info-outline_svg__a)">
			<path
				fill="currentColor"
				d="M11 7h2v2h-2V7Zm1 10c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1Zm0-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Z"
			/>
		</g>
		<defs>
			<clipPath id="info-outline_svg__a">
				<path fill="#fff" d="M0 0h24v24H0z" />
			</clipPath>
		</defs>
	</svg>
);
SvgInfoOutline.displayName = 'iconInfoOutline';
export default SvgInfoOutline;
