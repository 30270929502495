export function isValidHref(href: string): boolean {
	try {
		const parsed = new URL(href, 'http://dummy.org');
		if (/^https?:\/\//i.test(href) && !/^https?:\/\/[a-z0-9.-]+(\/|$)/i.test(href)) {
			// inconsistent behavior between browsers in handling unicode inside the domain
			// => let's simply consider it invalid
			return false;
		}
		return !!parsed;
	} catch (e) {
		return false;
	}
}
