import { useMediaQuery } from 'react-responsive';

import { isSsr } from '@change-corgi/core/ssr';
import type { BreakpointIndex } from '@change-corgi/design-system/theme';
import { useBreakpoints } from '@change-corgi/design-system/theme';

/**
 * @doc $DOC:ResponsiveHooks/CurrentBreakpointHook
 */
export function useCurrentBreakpoint(): BreakpointIndex {
	if (isSsr()) {
		throw new Error('Cannot access current breakpoint on server');
	}

	const breakpoints = useBreakpoints();

	const breakpointChecks = [
		true,
		useMediaQuery({ minWidth: breakpoints[0] }),
		useMediaQuery({ minWidth: breakpoints[1] }),
		useMediaQuery({ minWidth: breakpoints[2] }),
	];

	return breakpointChecks.lastIndexOf(true) as BreakpointIndex;
}
