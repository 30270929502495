import { useMemo } from 'react';

type PaginationArgs = {
	totalItemCount: number;
	itemsPerPage: number;
	siblingCount?: number;
	currentPage: number;
};

type PaginationRange = Array<number | typeof DOTS>;

export const DOTS = '…';

/*
    Create an array of certain length and set the elements within it from
    start value to end value.
*/
const range = (start: number, end: number): number[] => {
	const length = end - start + 1;
	return Array.from({ length }, (_, idx) => idx + start);
};

function determineLeftTruncatedRange(siblingCount: number, totalPageCount: number): PaginationRange {
	const leftItemCount = 3 + 2 * siblingCount;
	const leftRange = range(1, leftItemCount);

	return [...leftRange, DOTS, totalPageCount];
}

function determineRightTruncatedRange(siblingCount: number, totalPageCount: number): PaginationRange {
	const rightItemCount = 3 + 2 * siblingCount;
	const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);

	return [1, DOTS, ...rightRange];
}

function determineFullTruncatedRange(
	leftSiblingIndex: number,
	rightSiblingIndex: number,
	totalPageCount: number,
): PaginationRange {
	const middleRange = range(leftSiblingIndex, rightSiblingIndex);
	return [1, DOTS, ...middleRange, DOTS, totalPageCount];
}

export function usePaginationRange({
	totalItemCount,
	itemsPerPage,
	siblingCount = 1,
	currentPage,
}: PaginationArgs): PaginationRange {
	const paginationRange: PaginationRange = useMemo(() => {
		const totalPageCount = Math.ceil(totalItemCount / itemsPerPage);

		// Maximum items to show are siblings + firstPage + lastPage + currentPage + 2*DOTS
		// Keep this number of items returned constant so pagination control size doesn't change as user navigates
		const totalPageNumbers = 2 * siblingCount + 5;

		if (totalPageNumbers >= totalPageCount) {
			return range(1, totalPageCount);
		}

		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

		const shouldShowLeftDots = leftSiblingIndex > 2;
		const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

		if (!shouldShowLeftDots && shouldShowRightDots) {
			return determineLeftTruncatedRange(siblingCount, totalPageCount);
		}

		if (shouldShowLeftDots && !shouldShowRightDots) {
			return determineRightTruncatedRange(siblingCount, totalPageCount);
		}

		return determineFullTruncatedRange(leftSiblingIndex, rightSiblingIndex, totalPageCount);
	}, [totalItemCount, itemsPerPage, siblingCount, currentPage]);

	return paginationRange;
}
