import type { ThemeUIStyleObject } from 'theme-ui';

// https://theme-ui.com/components/variants
export type HeadingVariant = 'default' | 'light';

export const heading: Record<`heading_${HeadingVariant}`, ThemeUIStyleObject> = {
	heading_default: {
		fontWeight: 'heading',
	},
	heading_light: {
		fontWeight: 400,
	},
};
