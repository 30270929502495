/*
 * This file is generated by icons/template.js
 */
const SvgArrowForward = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
	</svg>
);
SvgArrowForward.displayName = 'iconArrowForward';
export default SvgArrowForward;
