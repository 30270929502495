import type { ChangeEvent } from 'react';

import { useControlled } from '@change-corgi/design-system/utils';

type UseRadioGroupParams = {
	name?: string;
	value?: string | null;
	defaultValue?: string | null;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};
type UseRadioGroupReturnType = {
	name?: string;
	value?: string | null;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};
export function useRadioGroup({
	name,
	value: controlledValue,
	defaultValue,
	onChange,
}: UseRadioGroupParams): UseRadioGroupReturnType {
	const [value, setValue] = useControlled(controlledValue, defaultValue);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setValue(event.currentTarget.value);
		onChange?.(event);
	};

	return { name, value, onChange: handleChange };
}
