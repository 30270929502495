import type { ThemeUIStyleObject } from 'theme-ui';

export function baseStyles(
	selected?: boolean,
	sx?: ThemeUIStyleObject,
	includeHoverStyles?: boolean,
): ThemeUIStyleObject {
	return {
		alignItems: 'center',
		bg: 'transparent',
		borderColor: 'neutral-grey600',
		borderRadius: '10000rem',
		borderStyle: 'solid',
		borderWidth: '1px',
		color: 'typography-primary',
		display: 'inline-flex',
		fontWeight: 'initial',
		fontSize: 16,
		height: 40,
		px: 16,
		...(selected && {
			borderColor: 'primary-changeRed',
			borderWidth: '2px',
			color: 'typography-highlight',
			fontWeight: 'bold',
			px: 15,
		}),
		...(includeHoverStyles && {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'&:hover': {
				backgroundColor: 'primary-greyBackground',
				borderWidth: '2px',
				cursor: 'pointer',
				px: 15,
			},
		}),
		...sx, // only required for storybook to take overrides into account
	};
}
