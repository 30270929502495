import type { ComponentProps, ComponentPropsWithRef, ForwardedRef, JSX, MouseEventHandler, ReactNode } from 'react';

import styled from '@emotion/styled';
import { Alert } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import { Translate } from '@change-corgi/core/react/i18n';
import { VisuallyHidden } from '@change-corgi/design-system/a11y';
import { Button, ButtonLink, FakeLink, Link } from '@change-corgi/design-system/components/actions';
import { Icon } from '@change-corgi/design-system/components/icon';
import type { IconComponent } from '@change-corgi/design-system/icons';
import { iconCheckCircle, iconClose, iconError, iconInfo, iconWarning } from '@change-corgi/design-system/icons';
import { Box, Container, Flex } from '@change-corgi/design-system/layout';
import { buttonResetStyles } from '@change-corgi/design-system/reset';
import { Text } from '@change-corgi/design-system/typography';

type Severity = 'critical' | 'info' | 'success' | 'warning';

const SEVERITY_MAP: Record<Severity, { bg: string; icon: IconComponent; iconColor: string }> = {
	critical: {
		bg: 'status-critical500',
		icon: iconError,
		iconColor: 'primary-white',
	},
	info: {
		bg: 'status-info500',
		icon: iconInfo,
		iconColor: 'primary-white',
	},
	success: {
		bg: 'status-positive500',
		icon: iconCheckCircle,
		iconColor: 'secondary-white',
	},
	warning: {
		bg: 'neutral-grey600',
		icon: iconWarning,
		iconColor: 'status-warning500',
	},
};

type ActionButton = {
	type: 'button';
	text: string;
} & Pick<ComponentProps<typeof Button>, 'icon' | 'iconPosition' | 'mode' | 'onClick'>;

type ActionLink = {
	type: 'link';
	text: string;
} & Pick<
	ComponentProps<typeof ButtonLink>,
	'to' | 'icon' | 'iconPosition' | 'mode' | 'target' | 'forceMode' | 'onClick'
>;

type Action = ActionButton | ActionLink;

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const CloseButton = styled.button({
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	...(buttonResetStyles as any),
	color: 'white',
	minWidth: 'auto',
	height: 32,
});

/* eslint-disable react/destructuring-assignment, @typescript-eslint/naming-convention */
function PrimaryActionComponent(props: Action) {
	const testIds = { 'data-testid': 'page-message-primary-action', 'data-qa': 'page-message-primary-action' };
	if (props.type === 'button') {
		const { type, text, ...buttonProps } = props;
		return (
			<Button {...testIds} {...buttonProps} variant="secondary">
				{text}
			</Button>
		);
	}

	const { type, text, to, ...linkProps } = props;
	return (
		<ButtonLink {...testIds} {...linkProps} to={to} variant="secondary">
			{text}
		</ButtonLink>
	);
}

function SecondaryActionComponent(props: Action) {
	const testIds = { 'data-testid': 'page-message-secondary-action', 'data-qa': 'page-message-secondary-action' };
	if (props.type === 'button') {
		const { type, text, ...buttonProps } = props;
		return (
			<FakeLink {...testIds} {...buttonProps} variant="light" sx={{ lineHeight: '24px' }}>
				{text}
			</FakeLink>
		);
	}

	const { type, text, to, ...linkProps } = props;
	return (
		<Link {...testIds} {...linkProps} to={to} variant="light" sx={{ lineHeight: '24px' }}>
			{text}
		</Link>
	);
}
/* eslint-enable react/destructuring-assignment, @typescript-eslint/naming-convention */

type Props = Omit<ComponentPropsWithRef<typeof Alert>, 'severity'> & {
	primaryAction?: Action;
	secondaryAction?: Action;
	message: string | ReactNode;
	dismissable?: boolean;
	onClose?: MouseEventHandler<HTMLButtonElement>;
	severity: Severity;
	containerVariant?: ComponentProps<typeof Container>['variant'];
};

function PageMessageInner(
	{
		containerVariant,
		dismissable = false,
		message,
		onClose,
		primaryAction,
		secondaryAction,
		severity,
		sx,
		...rest
	}: Props,
	ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
	return (
		<Alert
			sx={{
				bg: SEVERITY_MAP[severity].bg,
				borderRadius: 'none',
				...sx, // only required for storybook to take overrides into account
			}}
			ref={ref}
			{...rest}
		>
			<Container
				variant={containerVariant ?? 'default'}
				p={16}
				sx={{ display: 'flex', gap: 16, alignItems: [null, null, 'center'] }}
			>
				<Icon color={SEVERITY_MAP[severity].iconColor} icon={SEVERITY_MAP[severity].icon} size={32} />
				<Flex
					sx={{
						flexDirection: ['column', 'column', 'row'],
						gap: 16,
						width: '100%',
						alignItems: [null, null, 'center'],
					}}
				>
					<Text breakWord sx={{ flex: 1 }}>
						{message}
					</Text>
					{primaryAction || secondaryAction ? (
						<Box sx={{ flexShrink: 0 }}>
							<Flex sx={{ alignItems: 'center', flexWrap: 'wrap', gap: 16 }}>
								{primaryAction && <PrimaryActionComponent {...primaryAction} />}
								{secondaryAction && <SecondaryActionComponent {...secondaryAction} />}
							</Flex>
						</Box>
					) : null}
				</Flex>
				{dismissable && (
					<CloseButton
						data-testid="page-message-close-button"
						data-qa="page-message-close-button"
						onClick={(e) => onClose?.(e)}
					>
						<Icon icon={iconClose} size={32} />
						{/* using this approach for a11y for wider screen reader support
						https://www.sarasoueidan.com/blog/accessible-icon-buttons/ (technique #1) */}
						<VisuallyHidden>
							<Translate value="design-system.section-message.close-label" />
						</VisuallyHidden>
					</CloseButton>
				)}
			</Container>
		</Alert>
	);
}

/**
 * @doc $DOC:PageMessage
 */
export const PageMessage = forwardRef(PageMessageInner);
