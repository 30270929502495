import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { VisuallyHidden } from '@change-corgi/design-system/a11y';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconClose } from '@change-corgi/design-system/icons';
import { Box } from '@change-corgi/design-system/layout';

type Props = {
	onClick?: () => void;
};

export function ChipRemoveButton({ onClick }: Props): JSX.Element {
	return (
		<Box
			as="button"
			data-testid="chip-close-button"
			data-qa="chip-close-button"
			onClick={(e) => {
				e.stopPropagation();
				onClick?.();
			}}
			sx={{
				color: 'inherit',
				cursor: 'pointer',
				backgroundColor: 'transparent',
				border: 'none',
				pl: 8,
				pr: 0,
				boxSizing: 'content-box',
				height: '1em',
				width: '1em',
			}}
		>
			<Icon sx={{ fontSize: 'inherit' }} icon={iconClose} />
			{/* using this approach for a11y for wider screen reader support
				https://www.sarasoueidan.com/blog/accessible-icon-buttons/ (technique #1) */}
			<VisuallyHidden>
				<Translate value="design-system.chip.remove" />
			</VisuallyHidden>
		</Box>
	);
}
