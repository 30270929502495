import type { Interpolation } from '@emotion/react';
import type { StylePropertyValue } from 'theme-ui';

import type { EmptyIntersection } from '@change-corgi/core/types';

import type { Theme } from '../theme';

/**
 * This is an identity function only useful to get the proper typings of the theme in the callback
 */
export function derivedStyle<RES>(callback: (theme: Theme) => RES): StylePropertyValue<RES> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
	return callback as any;
}

/**
 * This is an identity function only useful to get the proper typings of the theme in the callback
 */
export function derivedGlobalStyle<RES>(callback: (theme: Theme) => RES): Interpolation<EmptyIntersection> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
	return callback as any;
}
