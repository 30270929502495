import type { ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import type { Property } from 'csstype';
import { Box as BoxBase } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import type { BoxVariant, ColorName, ResponsiveValue } from '@change-corgi/design-system/theme';

export type BoxProps = Omit<ComponentPropsWithRef<typeof BoxBase>, 'variant' | 'color' | 'backgroundColor' | 'bg'> & {
	variant?: BoxVariant;
	color?: ResponsiveValue<ColorName | Property.Color | undefined>;
	backgroundColor?: ResponsiveValue<ColorName | Property.BackgroundColor | undefined>;
};

function BoxInner({ children, variant, color, ...rest }: BoxProps, ref: ForwardedRef<HTMLElement>): JSX.Element {
	return (
		<BoxBase
			variant={variant ? `layout.box_${variant}` : undefined}
			// there is a weird typing issue with color in theme-ui even though it does work with a responsive array
			// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
			color={color as any}
			{...rest}
			ref={ref}
		>
			{children}
		</BoxBase>
	);
}

/**
 * @doc $DOC:Box
 */
export const Box = forwardRef(BoxInner);
