import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { JSX } from 'react';

import { useControlled } from '@change-corgi/design-system/utils';

type UseComboboxProps<OPTION> = {
	inputValue: string | undefined;
	onInputChange?: (event: React.ChangeEvent<HTMLInputElement> | null, newValue: string) => void;
	value: OPTION | null | undefined;
	defaultValue: OPTION | null;
	onChange?: (event: React.SyntheticEvent<HTMLElement> | null, option: OPTION | null) => void;
	options: readonly OPTION[];
	filterOptions?: (options: readonly OPTION[], inputValue: string) => readonly OPTION[];
	optionToString: (option: OPTION) => string;
	renderOption?: (option: OPTION) => JSX.Element;
	autoHighlight: boolean;
};

type UseComboboxReturn<OPTION> = {
	inputValue: string | undefined;
	handleInputChange: (event: React.ChangeEvent<HTMLInputElement> | null, newValue: string) => void;
	handleInputClick: () => void;
	handleInputKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	handleInputBlur: () => void;
	inputRef: React.RefObject<HTMLInputElement>;
	listboxRef: React.RefObject<HTMLUListElement>;
	open: boolean;
	filteredOptions: readonly OPTION[];
	optionToString: (option: OPTION) => string;
	renderOption?: (option: OPTION) => JSX.Element;
	handleSelect: (event: React.MouseEvent<HTMLLIElement>, index: number) => void;
	handleMouseEnter: (index: number) => void;
	handleMouseDown: (event: React.MouseEvent<HTMLLIElement>) => void;
	highlightedIndex: number;
	activeIndex: number;
};

// eslint-disable-next-line max-lines-per-function, max-statements
export function useComboBox<OPTION>({
	inputValue: controlledInputValue,
	onInputChange,
	value: controlledValue,
	defaultValue,
	onChange,
	options,
	filterOptions,
	optionToString,
	renderOption,
	autoHighlight,
}: UseComboboxProps<OPTION>): UseComboboxReturn<OPTION> {
	// input state
	const inputRef = useRef<HTMLInputElement>(null);
	const [inputValue, setInputValue] = useControlled(controlledInputValue, '');
	const [value, setValue] = useControlled(controlledValue, defaultValue ?? null);

	// dropdown state
	const listboxRef = useRef<HTMLUListElement>(null);
	const [open, setOpen] = useState(false);
	const [highlightedIndex, setHighlightedIndex] = useState(autoHighlight ? 0 : -1);
	// manages the 'interacted with' state of the combobox,
	// which allows us to track whether to show users full options or filtered
	const [pristine, setPristine] = useState(true);

	// options state
	const filteredOptions = useMemo(() => {
		if (!open) return [];

		if (filterOptions) return filterOptions(options, inputValue ?? '');

		const lowerCaseInput = (inputValue ?? '').toLocaleLowerCase();
		const lowerCaseValue = value ? optionToString(value).toLocaleLowerCase() : '';

		// return all options if first time combobox is opened (pristine)
		if (pristine && lowerCaseInput === lowerCaseValue) return options;

		// else return filtered options (onInputChange)
		return options.filter((option: OPTION) => optionToString(option).toLocaleLowerCase().startsWith(lowerCaseInput));
	}, [open, filterOptions, options, inputValue, value, optionToString, pristine]);

	const activeIndex = useMemo(() => {
		if (value === null || value === undefined) return -1;
		return filteredOptions.findIndex((option) => optionToString(option) === optionToString(value));
	}, [filteredOptions, optionToString, value]);

	// popover handlers
	const handleHighlightIndex = useCallback((index: number) => {
		setHighlightedIndex(index);
		listboxRef.current?.children[index]?.scrollIntoView({ block: 'nearest' });
	}, []);

	const handleClose = useCallback(() => {
		if (!open) return;

		setOpen(false);

		handleHighlightIndex(autoHighlight ? 0 : -1);
	}, [autoHighlight, handleHighlightIndex, open]);

	const handleOpen = useCallback(() => {
		if (open) return;

		setOpen(true);
		setPristine(true);
	}, [open]);

	// input handlers
	const handleClearInput = useCallback(
		(event: React.ChangeEvent<HTMLInputElement> | null) => {
			setInputValue('');
			onInputChange?.(event, '');
			if (value === null || value === undefined) return;
			setValue(null);
			onChange?.(event, null);
		},
		[setInputValue, onInputChange, value, setValue, onChange],
	);

	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement> | null, newValue: string) => {
			if (inputValue !== newValue) handleOpen();

			// set interactive state to non-pristine
			setPristine(false);

			// reset/clear values when all input is removed
			if (newValue === '') {
				handleClearInput(event);
				return;
			}

			// else handle input normally
			setInputValue(newValue);
			onInputChange?.(event, newValue);
			// and reset highlighted index
			handleHighlightIndex(autoHighlight ? 0 : -1);
		},
		[inputValue, handleOpen, setInputValue, onInputChange, handleHighlightIndex, autoHighlight, handleClearInput],
	);

	const handleInputClick = useCallback(() => {
		// clicking an empty input should toggle the dropdown
		if (inputValue === '') {
			setOpen((prevValue) => !prevValue);
			return;
		}

		// else just keep it open
		handleOpen();
	}, [handleOpen, inputValue]);

	const handleInputBlur = useCallback(() => {
		handleClose();

		// if we have a value, and a new value was not selected
		// reset the input value to the prev value
		if (
			value !== null &&
			value !== undefined &&
			inputValue?.toLocaleLowerCase() !== optionToString(value).toLocaleLowerCase()
		) {
			handleInputChange(null, optionToString(value));
		}
	}, [handleClose, value, inputValue, optionToString, handleInputChange]);

	// dropdown handlers
	const handleMouseDown = useCallback((event: React.MouseEvent<HTMLLIElement>) => {
		event.preventDefault();
	}, []);

	const handleMouseEnter = useCallback(
		(index: number) => {
			handleHighlightIndex(index);
		},
		[handleHighlightIndex],
	);

	const handleSelect = useCallback(
		(event: React.MouseEvent<HTMLLIElement> | React.KeyboardEvent<HTMLInputElement>, index: number) => {
			event.preventDefault();
			const newValue = filteredOptions[index];
			const newInputValue = optionToString(newValue);

			// update the input to the selected value
			handleInputChange(null, newInputValue);

			// set the new value
			setValue(newValue);
			onChange?.(event, newValue);

			// close dropdown, reset state
			handleClose();
		},
		[filteredOptions, optionToString, handleInputChange, setValue, onChange, handleClose],
	);

	// input + dropdown combo handlers (but is only used on the input component)
	const handleInputKeyDown = useCallback(
		// eslint-disable-next-line max-statements
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			switch (event.key) {
				case 'ArrowDown': {
					event.preventDefault(); // prevent page from scrolling
					// if popover isn't open, open and return
					if (!open) {
						handleOpen();
						return;
					}

					// else start navigating the listbox
					const index = highlightedIndex === -1 ? 0 : highlightedIndex + 1;
					handleHighlightIndex(index % filteredOptions.length);
					break;
				}
				case 'ArrowUp': {
					event.preventDefault(); // prevent cursor from moving to the beginning of the input text
					// if popover isn't open, open and return
					if (!open) {
						handleOpen();
						return;
					}

					// else start navigating the listbox
					const index =
						(highlightedIndex === -1 ? filteredOptions.length - 1 : highlightedIndex - 1) + filteredOptions.length;
					handleHighlightIndex(index % filteredOptions.length);
					break;
				}
				case 'Enter': {
					event.preventDefault(); // prevent submit
					if (!open) return;

					if (highlightedIndex !== -1) {
						handleSelect(event, highlightedIndex);
					}
					handleClose();
					break;
				}
				case 'Escape': {
					handleClose();
					break;
				}
				default:
					// do nothing
					break;
			}
		},
		[open, highlightedIndex, handleHighlightIndex, filteredOptions.length, handleOpen, handleClose, handleSelect],
	);

	// side-effects
	useEffect(() => {
		// initialize input value from value when controlled state (defaultValue)
		// value is source of truth for this
		const stringValue = value ? optionToString(value) : '';
		if (stringValue !== inputValue) {
			setInputValue(stringValue);
			onInputChange?.(null, stringValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// initialize the scroll position of listbox and highlighted index when `open`
		if (open && value !== null) {
			listboxRef.current?.querySelector('[aria-selected="true"]')?.scrollIntoView({ block: 'nearest' });
			handleHighlightIndex(activeIndex);
		}
	}, [activeIndex, handleHighlightIndex, open, value]);

	return {
		inputValue,
		handleInputChange,
		handleInputClick,
		handleInputKeyDown,
		handleInputBlur,
		inputRef,
		listboxRef,
		open,
		filteredOptions,
		renderOption,
		optionToString,
		activeIndex,
		highlightedIndex,
		handleSelect,
		handleMouseEnter,
		handleMouseDown,
	};
}
