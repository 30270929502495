import type { Property } from 'csstype';
import type { ThemeUIStyleObject } from 'theme-ui';

import type { ResponsiveArrayValue } from '@change-corgi/design-system/theme';

import { createBreakpointValuesGetter } from './getBreakpointValues';

/**
 * @doc $DOC:ResponsiveHooks/DisplayBreakpointValuesGetter
 */
export const getDisplayBreakpointValues = createBreakpointValuesGetter<Property.Display, 'block', 'none'>(
	'block',
	'none',
);

/**
 * @doc $DOC:ResponsiveHooks/DisplayBreakpointStylesGetter
 */
export function getDisplayBreakpointStyles(
	values: ResponsiveArrayValue<boolean>,
	trueValue: Property.Display = 'block',
	falseValue: Property.Display = 'none',
): ThemeUIStyleObject {
	return {
		display: getDisplayBreakpointValues(values, trueValue, falseValue),
	};
}
