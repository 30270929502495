import type { ComponentPropsWithoutRef, ForwardedRef, JSX, ReactNode } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import { useOptionalId } from '@change-corgi/design-system/core';
import { Box } from '@change-corgi/design-system/layout';
import type { FormSize, ResponsiveValue } from '@change-corgi/design-system/theme';

import { BareInput } from '../../BareInput';
import { useComboBox } from '../hooks/useComboBox';

import { OptionsList } from './OptionsList';

type RootProps = Omit<
	ComponentPropsWithoutRef<typeof Box>,
	'variant' | 'backgroundColor' | 'color' | 'onChange' | 'defaultValue' | 'value'
>;
type BareComboBoxProps<OPTION> = {
	id?: string;
	name: string;
	label: string;
	labelId: string;
	placeholder?: string;
	error?: ReactNode;
	size?: ResponsiveValue<FormSize>;
	inputValue?: string;
	onInputChange?: (event: React.ChangeEvent<HTMLInputElement> | null, newValue: string) => void;
	value?: OPTION | null;
	defaultValue?: OPTION | null;
	onChange?: (event: React.SyntheticEvent<HTMLElement> | null, option: OPTION | null) => void;
	options: readonly OPTION[];
	filterOptions?: (options: readonly OPTION[], inputValue: string) => readonly OPTION[];
	optionToString: (option: OPTION) => string;
	renderOption?: (option: OPTION) => JSX.Element;
	autoHighlight?: boolean;
};

type Props<OPTION> = RootProps & BareComboBoxProps<OPTION>;
function BareComboBoxInner<OPTION>(
	{
		id: idProp,
		name,
		label,
		labelId,
		placeholder,
		error,
		size,
		inputValue: controlledInputValue,
		onInputChange,
		value: controlledValue,
		defaultValue = null,
		onChange,
		options,
		filterOptions,
		optionToString,
		renderOption,
		autoHighlight = false,
		sx,
		...rest
	}: Props<OPTION>,
	ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
	const id = useOptionalId(idProp);
	const props = useComboBox<OPTION>({
		inputValue: controlledInputValue,
		onInputChange,
		value: controlledValue,
		defaultValue,
		onChange,
		options,
		filterOptions,
		optionToString,
		renderOption,
		autoHighlight,
	});

	return (
		<Box
			sx={{
				position: 'relative',
				display: 'inline-flex',
				flexDirection: 'column',
				width: '100%',
				...sx, // only required for storybook to take overrides into account
			}}
			{...rest}
		>
			<BareInput
				id={id}
				name={name}
				ref={ref}
				size={size}
				type="text"
				aria-autocomplete="list"
				aria-controls={`${id}-listbox`}
				aria-expanded={props.open}
				aria-haspopup="listbox"
				aria-activedescendant={props.activeIndex >= 0 ? `${id}-${props.activeIndex}` : undefined}
				role="combobox"
				onChange={(e) => {
					props.handleInputChange(e, e.currentTarget.value);
				}}
				onKeyDown={props.handleInputKeyDown}
				onClick={props.handleInputClick}
				onBlur={props.handleInputBlur}
				value={props.inputValue}
				placeholder={placeholder}
				data-error={!!error}
				autoComplete="off"
			/>
			{props.open && (
				<OptionsList<OPTION>
					id={id}
					labelId={labelId}
					listboxRef={props.listboxRef}
					activeIndex={props.activeIndex}
					highlightedIndex={props.highlightedIndex}
					options={props.filteredOptions}
					optionToString={props.optionToString}
					renderOption={props.renderOption}
					handleSelect={props.handleSelect}
					handleMouseEnter={props.handleMouseEnter}
					handleMouseDown={props.handleMouseDown}
				/>
			)}
		</Box>
	);
}

/**
 * @doc $DOC:BareComboBox
 */
export const BareComboBox = forwardRef(BareComboBoxInner);
