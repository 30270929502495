import type { ThemeUIStyleObject } from 'theme-ui';

/**
 * This can be used to reset the styles of a button in a "sx" property.
 *
 * Use only when necessary. The proper approach to buttons should be to use the button components provided by the library.
 *
 * @doc $DOC:button
 */
export const buttonResetStyles: ThemeUIStyleObject = {
	cursor: 'pointer',
	padding: 0,
	margin: 0,
	background: 'none',
	border: 'none',
	color: 'inherit',
	fontFamily: 'inherit',
	fontSize: 'inherit',
	fontWeight: 'inherit',
	lineHeight: 'inherit',
};
