import type { ThemeUIStyleObject } from 'theme-ui';

// https://theme-ui.com/components/variants
export type TextVariant = 'default';

export const text: Record<TextVariant, ThemeUIStyleObject> = {
	default: {
		fontFamily: 'body',
	},
};
