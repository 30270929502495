/*
 * This file is generated by icons/template.js
 */
const SvgTrendingFlat = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="m22 12-4-4v3H3v2h15v3z" />
	</svg>
);
SvgTrendingFlat.displayName = 'iconTrendingFlat';
export default SvgTrendingFlat;
