/** @jsxImportSource theme-ui */
import type { ComponentProps, JSX } from 'react';

import { Link } from '@change-corgi/core/react/router';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconChevronRight } from '@change-corgi/design-system/icons';
import { Text } from '@change-corgi/design-system/typography';

type Props = ComponentProps<typeof Link>;

/**
 * @doc $DOC:Breadcrumbs
 * Provides a secondary means of navigation
 */
export function BreadcrumbLink({ children, ...rest }: Props): JSX.Element {
	return (
		<>
			<Link sx={{ color: 'inherit', textDecorationColor: 'inherit' }} {...rest}>
				<Text>{children}</Text>
			</Link>
			<Icon aria-hidden="true" data-icon="true" icon={iconChevronRight} size={16} sx={{ ml: 8 }} />
		</>
	);
}
