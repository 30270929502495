import type { ComponentPropsWithRef, ForwardedRef, JSX, ReactNode } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import { useOptionalId } from '@change-corgi/design-system/core';
import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { FormError } from './components';

type Props = Omit<ComponentPropsWithRef<typeof Box>, 'variant' | 'backgroundColor' | 'color'> & {
	error?: ReactNode;
	errorId?: string;
	label?: string;
};

function CheckboxGroupInner(
	{ children, error, errorId, id: idProp, label, ...rest }: Props,
	ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
	const id = useOptionalId(idProp);
	return (
		<Box aria-labelledby={id} role="group" ref={ref} {...rest}>
			{label && (
				<Text as="div" id={id} size="caption" sx={{ fontWeight: 'bold' }} color="typography-primary" mb={4}>
					{label}
				</Text>
			)}
			{children}
			{error ? <FormError id={errorId}>{error}</FormError> : null}
		</Box>
	);
}

/**
 * @doc $DOC:CheckboxGroup
 */
export const CheckboxGroup = forwardRef(CheckboxGroupInner);
