import type { ComponentProps, ForwardedRef, JSX, ReactNode } from 'react';

import { Select as SelectBase } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import { generateBreakpointAttributes, normalizeResponsiveValue } from '@change-corgi/design-system/theme';
import type { FormSize, ResponsiveValue } from '@change-corgi/design-system/theme';

type Props = Omit<ComponentProps<typeof SelectBase>, 'size'> & {
	placeholder?: string;
	error?: ReactNode;
	size?: ResponsiveValue<FormSize>;
};

function BareSelectInner(
	{ error, variant, size, placeholder, children, ...rest }: Props,
	ref: ForwardedRef<HTMLSelectElement>,
): JSX.Element {
	const sizeResponsive = normalizeResponsiveValue(size);
	return (
		<SelectBase
			data-error={!!error}
			{...generateBreakpointAttributes('data-size', (idx) => sizeResponsive[idx] || 'default')}
			ref={ref}
			{...rest}
		>
			{placeholder && <option value="">{placeholder}</option>}
			{children}
		</SelectBase>
	);
}

/**
 * @doc $DOC:BareSelect
 */
export const BareSelect = forwardRef(BareSelectInner);
