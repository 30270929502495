/*
 * This file is generated by icons/template.js
 */
const SvgOpenInBrowser = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="M19 4H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6a2 2 0 0 0-2-2zm-7 6-4 4h3v6h2v-6h3l-4-4z" />
	</svg>
);
SvgOpenInBrowser.displayName = 'iconOpenInBrowser';
export default SvgOpenInBrowser;
