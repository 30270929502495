import type { JSX, PropsWithChildren } from 'react';

import { Icon } from '@change-corgi/design-system/components/icon';
import type { IconComponent } from '@change-corgi/design-system/icons';
import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

type Props = {
	icon: IconComponent;
	iconPosition?: 'start' | 'end';
};

export function ButtonWithIconWrapper({ children, icon, iconPosition }: PropsWithChildren<Props>): JSX.Element {
	return (
		<Flex sx={{ justifyContent: 'center' }}>
			<Flex sx={{ flexDirection: iconPosition === 'end' ? 'row-reverse' : 'row', alignItems: 'center' }}>
				<Icon icon={icon} data-icon data-icon-position={iconPosition || 'start'} aria-hidden="true" />
				<Text sx={{ fontSize: 'inherit', lineHeight: 'inherit' }} data-label>
					{children}
				</Text>
			</Flex>
		</Flex>
	);
}
