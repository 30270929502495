/*
 * This file is generated by icons/template.js
 */
const SvgRemove = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="M19 13H5v-2h14v2z" />
	</svg>
);
SvgRemove.displayName = 'iconRemove';
export default SvgRemove;
