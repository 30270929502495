import type { ComponentPropsWithRef, JSX } from 'react';

import { Icon } from '@change-corgi/design-system/components/icon';
import { iconChevronRight } from '@change-corgi/design-system/icons';
import { Text } from '@change-corgi/design-system/typography';

type Props = Omit<ComponentPropsWithRef<typeof Text>, 'variant'>;

/**
 * @doc $DOC:Breadcrumbs
 * Provides a secondary means of navigation
 */
export function Breadcrumb({ children, ...rest }: Props): JSX.Element {
	return (
		<>
			<Text sx={{ textDecoration: 'underline' }} {...rest}>
				{children}
			</Text>
			<Icon aria-hidden="true" data-icon="true" icon={iconChevronRight} size={16} sx={{ ml: 8 }} />
		</>
	);
}
