import type { ForwardedRef, JSX, PropsWithChildren } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import type { EmptyProps } from '@change-corgi/core/types';
import { Flex } from '@change-corgi/design-system/layout';

import { baseTabGroupListStyles, baseTabGroupStyles, baseTabRuleStyles } from '../shared/tabs/styles';

function TabLinkGroupInner(
	{ children }: PropsWithChildren<EmptyProps>,
	ref: ForwardedRef<HTMLUListElement>,
): JSX.Element {
	return (
		<Flex as="nav" sx={baseTabGroupStyles()}>
			<Flex sx={baseTabRuleStyles()} />
			<Flex as="ul" sx={baseTabGroupListStyles({})} ref={ref}>
				{children}
			</Flex>
		</Flex>
	);
}

/**
 * @doc $DOC:TabLinks
 */
export const TabLinkGroup = forwardRef(TabLinkGroupInner);
