/* eslint-disable @typescript-eslint/naming-convention */
/*
 * This represents the guidelines in terms of font sizes in the design system
 *
 * It still allows using other font sizes if the design requires it,
 * although it is not recommended for the design to be done that way
 */
export const fontSizes: Record<number, string> = {
	10: '10px',
	12: '12px',
	14: '14px',
	16: '16px',
	20: '20px',
	24: '24px',
	32: '32px',
	48: '48px',
	64: '64px',
};
