import type { JSX } from 'react';

import { Box } from '@change-corgi/design-system/layout';

type Props = { totalItems: number; activeIndex: number };
export function Dots({ totalItems, activeIndex }: Props): JSX.Element {
	return (
		<Box
			id="carousel-dots"
			aria-hidden
			sx={{
				display: 'grid',
				gridColumn: '1/-1',
				gap: 8,
				placeSelf: 'center',
				gridAutoFlow: 'column',
				padding: '16px 0',
			}}
		>
			{Array.from({ length: totalItems }).map((_, index) => (
				// eslint-disable-next-line react/no-array-index-key
				<Box key={`${index}-${totalItems}`}>
					<Box
						sx={{
							width: '8px',
							height: '8px',
							backgroundColor: activeIndex === index ? 'primary' : 'secondary',
							borderRadius: 'full',
							transform: activeIndex === index ? '' : 'scale(.625)',
						}}
					/>
				</Box>
			))}
		</Box>
	);
}
