import type { ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import { Link as LinkBase } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import type { LinkVariant } from '@change-corgi/design-system/theme';
import type { PropsWithoutAs } from '@change-corgi/design-system/types';

type Props = Omit<ComponentPropsWithRef<typeof LinkBase>, 'variant' | keyof React.ComponentPropsWithoutRef<'a'>> &
	React.ComponentPropsWithoutRef<'button'> & {
		variant?: LinkVariant;
	};

function FakeLinkInner(
	{ children, variant, ...rest }: PropsWithoutAs<'button', Props>,
	ref: ForwardedRef<HTMLButtonElement>,
): JSX.Element {
	return (
		<LinkBase
			as="button"
			variant={variant || 'primary'}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			{...(rest as any)}
			ref={ref as ForwardedRef<HTMLAnchorElement>}
			// TODO: move these to a global user agent stylesheet reset for use cases like translateHtml
			sx={{
				padding: 0,
				background: 'none',
				border: 0,
			}}
		>
			{children}
		</LinkBase>
	);
}

/**
 * For use cases where you want something that looks like a link but clicking it doesn't cause a navigation change
 *
 * @doc $DOC:FakeLink
 */
export const FakeLink = forwardRef(FakeLinkInner);
