/* eslint-disable @typescript-eslint/naming-convention */
/*
 * This represents the guidelines in terms of spacing in the design system
 *
 * It still allows using other spacing if the design requires it,
 * although it is not recommended for the design to be done that way
 */
export const space: Record<number, string> = {
	4: '4px',
	8: '8px',
	16: '16px',
	24: '24px',
	32: '32px',
	40: '40px',
	64: '64px',
	80: '80px',
	96: '96px',
};
