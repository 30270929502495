/*
 * This file is generated by icons/template.js
 */
const SvgTwitter = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 25 24" {...props}>
		<g clipPath="url(#twitter_svg__a)">
			<path
				fill="currentColor"
				d="M22.033 7.112c.015.213.015.426.015.64 0 6.502-4.949 13.994-13.995 13.994A13.9 13.9 0 0 1 .5 19.538c.396.046.777.061 1.188.061a9.85 9.85 0 0 0 6.106-2.102 4.927 4.927 0 0 1-4.599-3.41c.305.045.61.075.93.075.441 0 .883-.06 1.294-.167a4.92 4.92 0 0 1-3.944-4.827v-.061a4.954 4.954 0 0 0 2.223.624 4.915 4.915 0 0 1-2.193-4.096c0-.914.244-1.752.67-2.483A13.981 13.981 0 0 0 12.317 8.3a5.554 5.554 0 0 1-.122-1.126 4.917 4.917 0 0 1 4.92-4.92c1.415 0 2.695.595 3.593 1.554A9.684 9.684 0 0 0 23.83 2.62a4.906 4.906 0 0 1-2.163 2.711 9.86 9.86 0 0 0 2.833-.761 10.572 10.572 0 0 1-2.467 2.543Z"
			/>
		</g>
		<defs>
			<clipPath id="twitter_svg__a">
				<path fill="transparent" d="M.5 0h24v24H.5z" />
			</clipPath>
		</defs>
	</svg>
);
SvgTwitter.displayName = 'iconTwitter';
export default SvgTwitter;
