import type { JSX } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { VisuallyHidden } from '@change-corgi/design-system/a11y';
import { Icon } from '@change-corgi/design-system/components/icon';
import { Avatar } from '@change-corgi/design-system/components/media';
import { iconSupervisorAccount } from '@change-corgi/design-system/icons';
import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

type Props = {
	supporterCount: number;
	supporterCountLocalized?: string;
	userAvatarSrc?: string;
	userName: string;
};

export function Footer({ userAvatarSrc, supporterCount, supporterCountLocalized, userName }: Props): JSX.Element {
	const { localizeNumber, translatePlural } = useI18n();

	const supportersFullLabel = translatePlural('design-system.petition-card.supporter-count', supporterCount, {
		supporterCount: supporterCountLocalized || localizeNumber(supporterCount),
	});

	return (
		<Flex mb={16} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
			<Flex sx={{ alignItems: 'center', flex: 1 }}>
				<Avatar src={userAvatarSrc} alt="" size="xs" mr="8" />
				<Text size="caption" sx={{ fontWeight: 'bold', lineHeight: '16px' }} title={userName} lineClamp={2}>
					{userName}
				</Text>
			</Flex>
			<Flex sx={{ alignItems: 'center' }} ml={8}>
				<Icon size="xl" icon={iconSupervisorAccount} mr="8" />
				<Text size="micro" color="typography-secondary" title={supportersFullLabel}>
					<span aria-hidden="true">{supporterCountLocalized || localizeNumber(supporterCount)}</span>
					<VisuallyHidden>{supportersFullLabel}</VisuallyHidden>
				</Text>
			</Flex>
		</Flex>
	);
}
