import type { JSX, PropsWithChildren } from 'react';

import { ThemeUIProvider } from 'theme-ui';

import type { EmptyProps } from '@change-corgi/core/types';
import { __internal_theme__ as theme } from '@change-corgi/design-system/theme';

export function DesignSystem({ children }: PropsWithChildren<EmptyProps>): JSX.Element {
	return <ThemeUIProvider theme={theme}>{children}</ThemeUIProvider>;
}
