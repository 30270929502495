/** @jsxImportSource theme-ui */
import type { ForwardedRef, InputHTMLAttributes, JSX, ReactNode } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import { Flex } from '@change-corgi/design-system/layout';
import { derivedStyle, normalizeResponsiveValue, type ResponsiveValue } from '@change-corgi/design-system/theme';
import { getTextLineHeight, Text, type TextSize } from '@change-corgi/design-system/typography';

export type BooleanInputSize = 'default' | 'big';

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & {
	label: ReactNode;
	size?: BooleanInputSize;
};

const TEXT_SIZE: Record<BooleanInputSize, TextSize> = {
	default: 'caption',
	big: 'default',
};
const CB_PADDING_BOTTOM: Record<BooleanInputSize, number> = {
	// due to the checkbox not always being an even height, it's better to move it up 1px
	default: 1,
	big: 0,
};

function BooleanInputInner(
	{ label, name, size, disabled, checked, ...rest }: Props,
	ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
	const sizeResponsive = normalizeResponsiveValue(size || 'default');
	const textSizeResponsive = sizeResponsive.map((s) => TEXT_SIZE[s]) as ResponsiveValue<TextSize>;
	const cbHeightResponsive = sizeResponsive.map((s) => getTextLineHeight(TEXT_SIZE[s])) as ResponsiveValue<number>;
	const cbPbResponsive = sizeResponsive.map((s) => CB_PADDING_BOTTOM[s]) as ResponsiveValue<number>;

	return (
		<Flex
			as="label"
			sx={{
				color: disabled ? 'typography-disabled' : 'typography-primary',
				alignItems: 'flex-start',
			}}
			data-selected={checked || undefined}
		>
			<input
				name={name}
				disabled={disabled}
				checked={checked}
				sx={{
					height: cbHeightResponsive,
					accentColor: derivedStyle(({ colors }) => `${colors['secondary-blue']}`),
					marginRight: 8,
					paddingBottom: cbPbResponsive,
				}}
				{...rest}
				ref={ref}
			/>

			<Text className="boolean-input-label" size={textSizeResponsive}>
				{label}
			</Text>
		</Flex>
	);
}

export const BooleanInput = forwardRef(BooleanInputInner);
