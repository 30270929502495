import type { ForwardedRef, JSX, PropsWithChildren } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import { Box } from '@change-corgi/design-system/layout';

import type { TabsFunctionComponent } from './types';

type Props = PropsWithChildren<{
	name: string;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	__internal_selected?: boolean;
}>;

function TabPanelInner(
	// eslint-disable-next-line @typescript-eslint/naming-convention
	{ children, __internal_selected, ...rest }: Props,
	ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
	return (
		<Box
			role="tabpanel"
			tabIndex={0}
			sx={{
				display: __internal_selected ? 'block' : 'none',
			}}
			{...rest}
			ref={ref}
		>
			{children}
		</Box>
	);
}

/**
 * @doc $DOC:Tabs
 */
const TabPanel: TabsFunctionComponent<Props> = forwardRef(TabPanelInner);
TabPanel.tabsComponentName = 'TabPanel';
export { TabPanel };
