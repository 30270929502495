/*
 * This file is generated by icons/template.js
 */
const SvgExpandLess = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="m12 8-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
	</svg>
);
SvgExpandLess.displayName = 'iconExpandLess';
export default SvgExpandLess;
