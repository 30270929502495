import type { ForwardedRef, JSX } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import type { Translate, TranslateHtml, TranslatePlural, TranslatePluralHtml } from '@change-corgi/core/react/i18n';
import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { baseTabStyles } from '../shared/tabs/styles';

import type { TabsFunctionComponent } from './types';

type Props = {
	children:
		| string
		| ReadonlyArray<string | number>
		| React.ReactComponentElement<typeof Translate>
		| React.ReactComponentElement<typeof TranslateHtml>
		| React.ReactComponentElement<typeof TranslatePlural>
		| React.ReactComponentElement<typeof TranslatePluralHtml>;
	id?: string;
	name: string;
	onSelect?: (name: string) => void;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	__internal_selected?: boolean;
};

function TabInner(
	// eslint-disable-next-line @typescript-eslint/naming-convention
	{ children, name, onSelect, __internal_selected, ...rest }: Props,
	ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
	return (
		<Box
			as="li"
			role="tab"
			tabIndex={__internal_selected ? 0 : -1} // Unselected tabs have tab-index="-1" to remove them from the page tab sequence. See https://www.w3.org/WAI/ARIA/apg/patterns/tabs/examples/tabs-automatic/ for more detail.
			aria-selected={__internal_selected}
			onClick={() => onSelect && onSelect(name)}
			sx={{
				...baseTabStyles(__internal_selected),
				cursor: 'pointer',
			}}
			ref={ref}
			{...rest}
		>
			<Text>{children}</Text>
		</Box>
	);
}

/**
 * @doc $DOC:Tabs
 */
const Tab: TabsFunctionComponent<Props> = forwardRef(TabInner);
Tab.tabsComponentName = 'Tab';
export { Tab };
