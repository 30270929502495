import { mainFontFamily } from '@change-corgi/design-system/fonts';

export type FontVariant = 'body' | 'heading' | 'monospace';

export const baseFontFamily = `"${mainFontFamily}", "Helvetica Neue", Helvetica, Arial, Tahoma, sans-serif`;

export type Fonts = Record<FontVariant, string>;

export const fonts: Fonts = {
	body: baseFontFamily,
	heading: baseFontFamily,
	monospace: 'Menlo, monospace',
};
