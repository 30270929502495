export type RadiusType = 'none' | 'standard' | 'big' | 'full';

export type Radii = Record<RadiusType, string | number>;

export const radii: Radii = {
	none: 0,
	standard: '8px',
	big: '16px',
	full: '50%',
};
