import type { Theme } from 'theme-ui';

export const cards: Theme['cards'] = {
	primary: {
		borderRadius: 'standard',
		borderColor: 'neutral-grey600',
		borderStyle: 'solid',
		borderWidth: 1,
	},
};
