/*
 * This file is generated by icons/template.js
 */
const SvgSend = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="M2.01 21 23 12 2.01 3 2 10l15 2-15 2z" />
	</svg>
);
SvgSend.displayName = 'iconSend';
export default SvgSend;
