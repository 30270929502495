import type { JSX } from 'react';

import { useModalsContext } from './context';

export function ModalsList(): JSX.Element | null {
	const { modal } = useModalsContext();

	if (!modal) return null;

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const { modal: Modal, options, onClosed, persistentState, onPersistentStateUpdate } = modal;

	return (
		<Modal
			onRequestClose={onClosed}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			options={options}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			persistentState={persistentState}
			onPersistentStateUpdate={onPersistentStateUpdate}
		/>
	);
}
