/*
 * This file is generated by icons/template.js
 */
const SvgCapitol = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M20.873 10.315h-1.691c-.564-2.678-3.115-4.63-6.287-4.909V4.247h2.584l-.674-1.123L15.479 2h-4.157v3.406c-3.172.28-5.723 2.232-6.286 4.909H3.344v1.573h17.529v-1.573ZM20.876 19.977v-1.573h-1.573v-5.393h-2.022v5.393h-2.023v-5.393h-2.022v5.393h-2.023v-5.393H9.191v5.393H6.944v-5.393H4.92v5.393H3.348v1.573H2v1.574h20v-1.574h-1.124Z"
		/>
	</svg>
);
SvgCapitol.displayName = 'iconCapitol';
export default SvgCapitol;
