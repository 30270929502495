import type { ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';

import { BooleanInput } from './components';

type Props = Omit<ComponentPropsWithRef<typeof BooleanInput>, 'type'>;

function CheckboxInner(props: Props, ref: ForwardedRef<HTMLInputElement>): JSX.Element {
	return <BooleanInput type="checkbox" ref={ref} {...props} />;
}

/**
 * @doc $DOC:Checkbox
 */
export const Checkbox = forwardRef(CheckboxInner);
