import type { JSX } from 'react';

import { Box } from '@change-corgi/design-system/layout';

import type { ModalOptions, ModalOptionsDefault, ModalVariantProps } from '../../shared/types';
import { useModalZIndex } from '../../useModalZIndex';
import { ModalBody } from '../ModalBody';
import { ModalHeading } from '../ModalHeading';

export function DefaultModal<
	OPTIONS extends ModalOptions = ModalOptionsDefault,
	RESULT = undefined,
	STATE = undefined,
>({
	options,
	heading,
	title,
	body,
	closeModal,
	closeButton,
	onCloseButtonClick,
	persistentState,
	onPersistentStateUpdate,
	stylesOverrides,
	...props
}: ModalVariantProps<OPTIONS, RESULT, STATE>): JSX.Element {
	const modalZIndex = useModalZIndex();

	return (
		<Box
			sx={{
				paddingY: 16,
				paddingX: 16,
				borderRadius: 'standard',
				backgroundColor: 'primary-white',
				color: 'typography-primary',
				position: 'relative',
				width: ['calc(100vw - 32px)', 480],
				maxHeight: 'calc(100% - 32px)',
				overflowY: 'auto',
				overflowX: 'hidden',
				WebkitOverflowScrolling: 'touch',
				...stylesOverrides,
			}}
			{...props}
		>
			<ModalHeading
				options={options}
				title={title}
				heading={heading}
				closeModal={closeModal}
				modalZIndex={modalZIndex}
				closeButton={closeButton}
				onCloseButtonClick={onCloseButtonClick}
				persistentState={persistentState}
				onPersistentStateUpdate={onPersistentStateUpdate}
			/>
			<ModalBody
				options={options}
				body={body}
				closeModal={closeModal}
				modalZIndex={modalZIndex}
				persistentState={persistentState}
				onPersistentStateUpdate={onPersistentStateUpdate}
			/>
		</Box>
	);
}
