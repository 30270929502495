/*
 * This file is generated by icons/template.js
 */
const SvgSnapchat = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 32 32" {...props}>
		<path
			fill="currentColor"
			d="M4.25 26.412c.363 1.6.754 2.263 2.853 1.919.61-.101 1.373-.228 2.312-.073 1.557.258 5.674 5.504 11.53.877 1.142-.8 1.766-1.243 3.983-.808.968.188 2.21-.01 2.816-1.915 5.785-1.041 4.543-4.232 2.81-4.51-3.014-.492-5.013-3.603-5.226-4.595.212-.13.676-.292.972-.393 4.887-1.691 2.167-5.806-1.156-4.536.217-3.467.28-6.244-2.556-8.907C19.573.65 14.916.52 11.875 1.855 6.573 4.172 6.615 8.662 6.85 12.43c-2.114-.808-3.547.356-3.778 1.55-.349 1.88 1.448 2.513 2.52 2.89.375.132 1 .352 1.095.423-.116.134-.788 3.122-4.768 4.442-.76.252-1.479.49-1.79 1.187-.186.416-.171.883.047 1.385.538 1.248 2.284 1.784 4.072 2.104Zm4.153-8.022c.88-1.774-.275-2.74-2.144-3.4-.395-.138-1.128-.396-1.224-.634.033-.084.296-.252.545-.252.077.006.299.09.445.146 6.323 2.394-1.137-7.166 6.651-10.568 2.385-1.046 6.19-.963 8.545 1.244 3.775 3.54.806 8.712 2.443 9.457 1.165.53 2.28-.285 2.683-.297.313 0 .586.186.616.253.005.236-.82.521-1.314.692-2.28.788-2.992 1.732-1.702 4.083.972 1.773 3.028 3.98 5.75 4.649-1.91 1.104-3.36.132-3.785 1.801-.219.887-.359.856-.6.81-6.755-1.323-6.293 5.693-13.159 1.108-2.342-1.564-2.8-1.398-5.846-1.046-.128-.502-.21-1.046-.388-1.374-.482-.891-2.263-.44-3.659-1.335 2.801-.959 4.696-2.087 6.143-5.337Z"
		/>
	</svg>
);
SvgSnapchat.displayName = 'iconSnapchat';
export default SvgSnapchat;
