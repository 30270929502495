import type { ComponentProps, ForwardedRef, JSX } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';

import { InputWrapper } from '../components';

import { BareComboBox } from './components/BareComboBox';

type InputWrapperProps = Omit<ComponentProps<typeof InputWrapper>, 'errorId' | 'helperTextId' | 'labelId'>;
type ComboBoxProps<OPTION> = Omit<ComponentProps<typeof BareComboBox<OPTION>>, 'labelId'>;
type Props<OPTION> = InputWrapperProps & ComboBoxProps<OPTION>;

function ComboBoxInner<OPTION>(
	{ caption, error, helperText, id, label, hideLabel, size, ...rest }: Props<OPTION>,
	ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
	const errorId = `${id}___error`;
	const helperTextId = `${id}___helpertext`;
	const labelId = `${id}___label`;

	return (
		<InputWrapper
			caption={caption}
			error={error}
			errorId={errorId}
			helperText={helperText}
			helperTextId={helperTextId}
			label={label}
			labelId={labelId}
			hideLabel={hideLabel}
			size={size}
		>
			<BareComboBox<OPTION>
				aria-describedby={`${helperTextId} ${errorId}`}
				id={id}
				label={label}
				labelId={labelId}
				error={error}
				size={size}
				ref={ref}
				{...rest}
			/>
		</InputWrapper>
	);
}

/**
 * @doc $DOC:ComboBox
 */
export const ComboBox = forwardRef(ComboBoxInner);
