import type { ChangeEvent, ComponentPropsWithRef, ForwardedRef, JSX, ReactNode } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import { useOptionalId } from '@change-corgi/design-system/core';
import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { FormError } from '../components';

import { Provider as RadioGroupProvider } from './hooks/context';
import { useRadioGroup } from './hooks/useRadioGroup';

type Props = Omit<ComponentPropsWithRef<typeof Box>, 'variant' | 'backgroundColor' | 'color' | 'onChange'> & {
	error?: ReactNode;
	errorId?: string;
	label?: string;
	name?: string;
	value?: string | null;
	defaultValue?: string | null;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

function RadioGroupInner(
	{ children, error, errorId, id: idProp, label, name, value, onChange, defaultValue, ...rest }: Props,
	ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
	const id = useOptionalId(idProp);

	const radioGroupContext = useRadioGroup({ name, value, onChange, defaultValue });

	return (
		<Box aria-labelledby={id} role="radiogroup" ref={ref} {...rest}>
			{label && (
				<Text as="div" id={id} size="caption" sx={{ fontWeight: 'bold' }} color="typography-primary" mb={4}>
					{label}
				</Text>
			)}
			<RadioGroupProvider {...radioGroupContext}>{children}</RadioGroupProvider>
			{error ? <FormError id={errorId}>{error}</FormError> : null}
		</Box>
	);
}

/**
 * @doc $DOC:RadioGroup
 */
export const RadioGroup = forwardRef(RadioGroupInner);
