import type { ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import type { IconSize } from '@change-corgi/design-system/components/icon';
import { Icon } from '@change-corgi/design-system/components/icon';
import type { IconComponent } from '@change-corgi/design-system/icons';
import { iconCheckCircle, iconError, iconInfo, iconWarning } from '@change-corgi/design-system/icons';
import type { ResponsiveValue } from '@change-corgi/design-system/theme';
import type { TextSize } from '@change-corgi/design-system/typography';
import { Text } from '@change-corgi/design-system/typography';

export type Variant = 'error' | 'info' | 'success' | 'warning';

export const variants: Variant[] = ['error', 'info', 'success', 'warning'];

const VARIANT_MAP: Record<Variant, { icon: IconComponent; iconColor?: string; labelColor?: string }> = {
	error: {
		icon: iconError,
		iconColor: 'primary-changeRed',
		labelColor: 'primary-changeRed',
	},
	info: {
		icon: iconInfo,
	},
	success: {
		icon: iconCheckCircle,
		iconColor: 'secondary-green',
		labelColor: 'secondary-green',
	},
	warning: {
		icon: iconWarning,
		iconColor:
			'status-warning500' /* TODO: the warning icon on the figma has a black exclamation point, this one is white */,
	},
};

type Props = Omit<ComponentPropsWithRef<typeof Text>, 'variant' | 'ellipsis' | 'lineClamp'> & {
	icon?: IconComponent;
	iconColor?: string;
	labelColor?: string;
	variant?: Variant;
};

const DEFAULT_ICON = iconInfo;

const ICON_SIZE_MAPPING: Record<TextSize, IconSize> = {
	micro: 's',
	caption: 'm',
	small: 'l',
	default: 'xl',
	large: 'xxl',
	huge: 'xxxl',
};
const ICON_MARGIN_MAPPING: Record<TextSize, number> = {
	micro: 4,
	caption: 4,
	small: 4,
	default: 8,
	large: 8,
	huge: 8,
};

function InlineMessageInner(
	{ children, icon, iconColor, labelColor, variant, size = 'default', sx, ...rest }: Props,
	ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
	const iconSize: ResponsiveValue<IconSize> =
		typeof size === 'string'
			? ICON_SIZE_MAPPING[size]
			: (size.map((s) => (s ? ICON_SIZE_MAPPING[s] : s)) as ResponsiveValue<IconSize>);
	const iconMargin: ResponsiveValue<number> =
		typeof size === 'string'
			? ICON_MARGIN_MAPPING[size]
			: (size.map((s) => (s ? ICON_MARGIN_MAPPING[s] : s)) as ResponsiveValue<number>);
	return (
		<Text
			as="div"
			ref={ref}
			size={size}
			sx={{
				display: 'flex',
				alignItems: 'start',
				...sx, // only required for storybook to take overrides into account
			}}
			{...rest}
		>
			{/* using this trick to align the icon with the first line of the message */}
			<Text as="div" sx={{ display: 'flex', alignItems: 'center', fontSize: 'inherit', lineHeight: 'inherit' }}>
				{/* invisible character that forces the container to apply the line-height */}
				&#8205;
				<Icon
					color={iconColor || (variant && VARIANT_MAP[variant]?.iconColor)}
					icon={icon || (variant && VARIANT_MAP[variant]?.icon) || DEFAULT_ICON}
					size={iconSize}
					mr={iconMargin}
				/>
			</Text>
			<Text
				color={labelColor || (variant && VARIANT_MAP[variant].labelColor)}
				sx={{ flex: 1, fontWeight: 'bold', fontSize: 'inherit', lineHeight: 'inherit' }}
			>
				{children}
			</Text>
		</Text>
	);
}

/**
 * @doc $DOC:InlineMessage
 */
export const InlineMessage = forwardRef(InlineMessageInner);
