/*
 * This file is generated by icons/template.js
 */
const SvgPersonStar = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M9 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4Zm10-2.3 1.94 1.228c.355.224.79-.108.697-.529l-.515-2.308 1.716-1.556c.313-.284.145-.822-.267-.856l-2.257-.2-.884-2.182a.46.46 0 0 0-.86 0l-.884 2.177-2.257.2c-.412.035-.58.573-.267.856l1.716 1.556-.515 2.308c-.093.421.342.754.697.529L19 11.7Z"
		/>
	</svg>
);
SvgPersonStar.displayName = 'iconPersonStar';
export default SvgPersonStar;
