/* eslint-disable @typescript-eslint/naming-convention */
import type { ThemeUIStyleObject } from 'theme-ui';

// https://theme-ui.com/components/variants
export type LinkVariant = 'primary' | 'light';

const commonLinkStyles: ThemeUIStyleObject = {
	cursor: 'pointer',
	fontFamily: 'body',
	textDecoration: 'underline',
	fontWeight: 'bold',
	'&:disabled, &:disabled:hover': {
		cursor: 'not-allowed',
		textDecoration: 'none',
	},
};

export const links: Record<LinkVariant, ThemeUIStyleObject> = {
	primary: {
		...commonLinkStyles,
		color: 'secondary-blue',
		cursor: 'pointer',
		textDecoration: 'underline',
		'&:active': {
			color: 'primary-changeRed',
		},
		'&:disabled, &:disabled:hover': {
			color: 'neutral-grey600',
		},
		'&:focus, &:hover': {
			color: 'primary-black',
			textDecoration: 'none',
		},
	},
	light: {
		...commonLinkStyles,
		color: 'primary-white',
	},
};
