/*
 * This file is generated by icons/template.js
 */
const SvgRoundSquare = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="M3 5v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2z" />
	</svg>
);
SvgRoundSquare.displayName = 'iconRoundSquare';
export default SvgRoundSquare;
