import type { ThemeUIStyleObject } from 'theme-ui';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function baseTabStyles(__internal_selected?: boolean): ThemeUIStyleObject {
	return {
		borderBottom: '4px solid',
		borderColor: __internal_selected ? 'primary-changeRed' : 'transparent',
		color: __internal_selected ? 'typography-highlight' : 'typography-primary',
		flexShrink: -0,
		pb: 8,
		textAlign: 'center',
		whiteSpace: 'nowrap',
		px: 16,
	};
}

export function baseTabGroupStyles(): ThemeUIStyleObject {
	return {
		display: 'inline-flex',
		flexDirection: 'column',
		width: '100%',
		position: 'relative',
	};
}

export function baseTabRuleStyles(): ThemeUIStyleObject {
	return {
		position: 'absolute',
		borderBottom: '1px solid',
		borderColor: 'neutral-grey100',
		flexGrow: 1,
		bottom: 0,
		width: '100%',
	};
}

export function baseTabGroupListStyles({ spreadTabs }: { spreadTabs?: boolean }): ThemeUIStyleObject {
	return {
		position: 'relative', // needed to appear on top of the rule
		display: 'inline-flex',
		listStyleType: 'none',
		px: 0,
		width: '100%',
		whiteSpace: 'nowrap',
		overflowX: 'auto',
		overflowY: 'hidden',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		'::-webkit-scrollbar': {
			display: 'none',
		},
		// eslint-disable-next-line @typescript-eslint/naming-convention
		'& > *': {
			flexGrow: spreadTabs ? 1 : undefined,
		},
	};
}
