import { useLayoutEffect, useMemo, useRef } from 'react';
import type { JSX, MouseEvent, PropsWithChildren } from 'react';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import FocusTrap from 'focus-trap-react';

import { Flex } from '@change-corgi/design-system/layout';

import { closeButtonDataAttribute, initialFocusDataAttribute } from '../shared/consts';
import { useRootAppInertToggle } from '../shared/hooks/inertToggle';

type Props = {
	modalId: string;
	onClick?: () => void;
};

// TODO retrieve this from the DesignSystem provider config?
const rootAppSelector = '#rootApp';

export function ModalOverlay({ modalId, children, onClick: onClickP }: PropsWithChildren<Props>): JSX.Element {
	const overlayEl = useRef<HTMLDivElement | null>(null);

	const { onClick, onMouseDown } = useMemo(() => {
		// this is necessary to avoid closing the modal on a text selection that ends on the overlay
		let mouseDownOnOverlay = false;
		return {
			onMouseDown: (event: MouseEvent) => {
				mouseDownOnOverlay = event.target === overlayEl.current;
			},
			onClick: (event: MouseEvent) => {
				if (mouseDownOnOverlay && event.target === overlayEl.current) {
					onClickP?.();
				}
			},
		};
	}, [onClickP]);

	useLayoutEffect(() => {
		const elt = overlayEl.current;
		if (overlayEl.current) {
			disableBodyScroll(overlayEl.current, {
				// https://github.com/willmcpo/body-scroll-lock#allowtouchmove
				allowTouchMove: (element) => {
					let el: HTMLElement | Element | null = element;
					while (el && el !== document.body) {
						if (el === overlayEl.current) {
							return true;
						}

						// eslint-disable-next-line no-param-reassign
						el = el.parentElement;
					}
					return false;
				},
			});
		}
		return () => {
			if (elt) enableBodyScroll(elt);
		};
	}, []);

	// using inert on top of focus-trap to account for buggy screen readers
	const { setInertOff, setInertOn } = useRootAppInertToggle(rootAppSelector);

	return (
		<FocusTrap
			focusTrapOptions={{
				initialFocus: () =>
					document.querySelector<HTMLElement>(`#${modalId} [${initialFocusDataAttribute}]`) ||
					document.querySelector<HTMLElement>(`#${modalId} [${closeButtonDataAttribute}]`) ||
					document.querySelector<HTMLElement>(`#${modalId}`) ||
					false,
				fallbackFocus: `#${modalId}`,
				escapeDeactivates: false,
				onPostActivate: setInertOn,
				onDeactivate: setInertOff,
				// this is useful mostly for storybook to be able to interact
				// with a docs page when switching from the story page
				// otherwise user selection stays impossible
				// due to storybook hiding the story instead of unmounting it
				clickOutsideDeactivates: true,
			}}
		>
			<Flex
				sx={{
					justifyContent: 'center',
					alignItems: 'center',
					position: 'fixed',
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
					zIndex: 'modalOverlay',
					background: 'hsla(305, 1%, 61%, 0.83)',
				}}
				onMouseDown={onMouseDown}
				onClick={onClick}
				tabIndex={-1}
				ref={overlayEl}
				data-testid="modal-overlay"
			>
				{children}
			</Flex>
		</FocusTrap>
	);
}
