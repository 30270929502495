/*
 * This file is generated by icons/template.js
 */
const SvgPlayArrow = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="M8 5v14l11-7z" />
	</svg>
);
SvgPlayArrow.displayName = 'iconPlayArrow';
export default SvgPlayArrow;
