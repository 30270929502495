/*
 * This file is generated by icons/template.js
 */
const SvgTrendingUpCircle = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm-5.149-4.706a.624.624 0 0 1-.385-.124c-.351-.232-.452-.712-.234-1.086l2.894-4.894a.71.71 0 0 1 1.021-.249l2.476 1.655 2.292-3.915-1.12.267c-.402.107-.804-.16-.887-.587-.1-.427.15-.854.552-.943l2.86-.694a.736.736 0 0 1 .888.587l.652 3.043c.1.427-.15.854-.552.943-.05.018-.117.018-.167.018-.335 0-.636-.25-.72-.605l-.25-1.192-2.694 4.573a.71.71 0 0 1-1.021.25L9.98 12.684 7.47 16.92a.687.687 0 0 1-.619.374Z"
			clipRule="evenodd"
		/>
	</svg>
);
SvgTrendingUpCircle.displayName = 'iconTrendingUpCircle';
export default SvgTrendingUpCircle;
