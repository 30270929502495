import type { ComponentProps, ForwardedRef, JSX } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import { Flex } from '@change-corgi/design-system/layout';

import { baseTabGroupListStyles, baseTabGroupStyles, baseTabRuleStyles } from '../shared/tabs/styles';

import type { Tab } from './Tab';
import type { TabsFunctionComponent } from './types';

type Props = {
	children:
		| React.ReactElement<ComponentProps<typeof Tab>>
		| ReadonlyArray<React.ReactElement<ComponentProps<typeof Tab>> | null | false | undefined>;
	spreadTabs?: boolean;
};

function TabGroupInner({ children, spreadTabs }: Props, ref: ForwardedRef<HTMLUListElement>): JSX.Element {
	return (
		<Flex sx={baseTabGroupStyles()}>
			<Flex sx={baseTabRuleStyles()} />
			<Flex as="ul" role="tablist" sx={baseTabGroupListStyles({ spreadTabs })} ref={ref}>
				{children}
			</Flex>
		</Flex>
	);
}

/**
 * @doc $DOC:Tabs
 */
const TabGroup: TabsFunctionComponent<Props> = forwardRef(TabGroupInner);
TabGroup.tabsComponentName = 'TabGroup';
export { TabGroup };
