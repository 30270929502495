import type { ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import { Flex } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import { Icon } from '@change-corgi/design-system/components/icon';
import type { IconComponent } from '@change-corgi/design-system/icons';
import { Text } from '@change-corgi/design-system/typography';

import { ChipRemoveButton } from './components/ChipRemoveButton';
import { baseStyles } from './shared/baseStyles';

type Props = ComponentPropsWithRef<typeof Flex> & {
	icon?: IconComponent;
	onRemoveClick?: () => void;
	selected?: boolean;
	showRemoveButton?: boolean;
};

function ChipInner(
	{ children, icon, onClick, onRemoveClick, selected, showRemoveButton, sx, ...rest }: Props,
	ref: ForwardedRef<HTMLImageElement>,
): JSX.Element {
	return (
		<Flex
			ref={ref}
			sx={{
				...baseStyles(selected, sx, !!onClick),
				...sx, // only required for storybook to take overrides into account
			}}
			onClick={onClick}
			{...rest}
		>
			{icon && <Icon size="m" icon={icon} mr={8} />}
			<Text size="default" ellipsis>
				{children}
			</Text>
			{showRemoveButton && <ChipRemoveButton onClick={onRemoveClick} />}
		</Flex>
	);
}

/**
 * @doc $DOC:Chip
 */
export const Chip = forwardRef(ChipInner);
