/*
 * This file is generated by icons/template.js
 */
const SvgTrendingUp = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="m16 6 2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z" />
	</svg>
);
SvgTrendingUp.displayName = 'iconTrendingUp';
export default SvgTrendingUp;
