import type { JSX } from 'react';

import { Heading } from '@change-corgi/design-system/typography';

import type { ModalHeadingProps, ModalOptions, ModalOptionsDefault, ModalOptionsProps } from '../shared/types';

type Props<OPTIONS extends ModalOptions = ModalOptionsDefault, RESULT = undefined> = Pick<
	ModalHeadingProps<OPTIONS, RESULT>,
	'options' | 'initialFocusAttributes'
> &
	Required<Pick<ModalOptionsProps<OPTIONS, RESULT>, 'title'>>;

export function DefaultModalHeading<OPTIONS extends ModalOptions = ModalOptionsDefault, RESULT = undefined>({
	options,
	title: Title,
	initialFocusAttributes,
}: Props<OPTIONS, RESULT>): JSX.Element {
	return (
		// TODO extract smart focus logic from corgi to avoid tabindex=-1?
		<Heading
			as="h1"
			size="h5"
			breakWord
			tabIndex={-1}
			sx={{
				marginTop: 4,
				marginBottom: 16,
				// padding to leave space for the close icon
				paddingRight: 32,
			}}
			{...initialFocusAttributes}
		>
			{typeof Title === 'string' ? Title : <Title options={options} />}
		</Heading>
	);
}
