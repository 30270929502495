/*
 * This file is generated by icons/template.js
 */
const SvgQrCode = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 32 32" {...props}>
		<g fill="currentColor" clipPath="url(#qr-code_svg__a)">
			<path d="M6.667 14.667H12c1.467 0 2.667-1.2 2.667-2.667V6.667C14.667 5.2 13.467 4 12 4H6.667A2.675 2.675 0 0 0 4 6.667V12c0 1.467 1.2 2.667 2.667 2.667Zm0-8H12V12H6.667V6.667ZM6.667 28H12c1.467 0 2.667-1.2 2.667-2.667V20c0-1.467-1.2-2.667-2.667-2.667H6.667A2.674 2.674 0 0 0 4 20v5.333C4 26.8 5.2 28 6.667 28Zm0-8H12v5.333H6.667V20ZM17.333 6.667V12c0 1.467 1.2 2.667 2.667 2.667h5.333C26.8 14.667 28 13.467 28 12V6.667C28 5.2 26.8 4 25.333 4H20a2.674 2.674 0 0 0-2.667 2.667Zm8 5.333H20V6.667h5.333V12ZM28 27.333V26a.66.66 0 0 0-.667-.667H26a.66.66 0 0 0-.667.667v1.333A.66.66 0 0 0 26 28h1.333a.66.66 0 0 0 .667-.667ZM17.333 18v1.333A.66.66 0 0 0 18 20h1.333a.66.66 0 0 0 .667-.667V18a.66.66 0 0 0-.667-.667H18a.66.66 0 0 0-.667.667ZM22 20h-1.333a.66.66 0 0 0-.667.667V22a.66.66 0 0 0 .667.667H22a.66.66 0 0 0 .667-.667v-1.333A.66.66 0 0 0 22 20ZM17.333 23.333v1.334a.66.66 0 0 0 .667.666h1.333a.66.66 0 0 0 .667-.666v-1.334a.66.66 0 0 0-.667-.666H18a.66.66 0 0 0-.667.666ZM20.667 28H22a.66.66 0 0 0 .667-.667V26a.66.66 0 0 0-.667-.667h-1.333A.66.66 0 0 0 20 26v1.333a.66.66 0 0 0 .667.667ZM23.333 25.333h1.334a.66.66 0 0 0 .666-.666v-1.334a.66.66 0 0 0-.666-.666h-1.334a.66.66 0 0 0-.666.666v1.334a.66.66 0 0 0 .666.666ZM24.667 17.333h-1.334a.66.66 0 0 0-.666.667v1.333a.66.66 0 0 0 .666.667h1.334a.66.66 0 0 0 .666-.667V18a.66.66 0 0 0-.666-.667ZM26 22.667h1.333A.66.66 0 0 0 28 22v-1.333a.66.66 0 0 0-.667-.667H26a.66.66 0 0 0-.667.667V22a.66.66 0 0 0 .667.667Z" />
		</g>
		<defs>
			<clipPath id="qr-code_svg__a">
				<path fill="#fff" d="M0 0h32v32H0z" />
			</clipPath>
		</defs>
	</svg>
);
SvgQrCode.displayName = 'iconQrCode';
export default SvgQrCode;
