import type { ComponentPropsWithRef, ForwardedRef, JSX, PropsWithChildren } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import { Box } from '@change-corgi/design-system/layout';

import { useContext as useAccordionContext } from '../hooks/context';

type Props = PropsWithChildren<ComponentPropsWithRef<typeof Box>>;

function AccordionDetailsInner({ children, ...rest }: Props, ref: ForwardedRef<HTMLElement>): JSX.Element | null {
	const { data } = useAccordionContext();
	const { expanded, renderDetailsOnlyWhenExpanded } = data;

	if (renderDetailsOnlyWhenExpanded && !expanded) return null;

	return (
		<Box
			as="section"
			{...rest}
			aria-labelledby={data.id}
			hidden={!expanded}
			id={`${data.id}-details`}
			sx={{ display: expanded ? 'block' : 'none', px: 16, pb: 16 }}
			ref={ref}
		>
			{children}
		</Box>
	);
}

/**
 * @doc $DOC:Accordion
 */
export const AccordionDetails = forwardRef(AccordionDetailsInner);
