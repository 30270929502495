import type { ComponentPropsWithRef, ForwardedRef, JSX, PropsWithoutRef } from 'react';
import { Children, useCallback } from 'react';

import { Button as ButtonBase } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import { VisuallyHidden } from '@change-corgi/design-system/a11y';
import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';
import { Flex } from '@change-corgi/design-system/layout';
import type { ButtonSize, ButtonVariant, ColorName, ResponsiveValue } from '@change-corgi/design-system/theme';
import { generateBreakpointAttributes, normalizeResponsiveValue } from '@change-corgi/design-system/theme';
import type { ComponentWithAs, PropsWithoutAs } from '@change-corgi/design-system/types';
import { Text } from '@change-corgi/design-system/typography';

import { ButtonWithIconWrapper } from './common/ButtonWithIconWrapper';
import type { ButtonProps } from './shared/types';

const DecoratedButton: ComponentWithAs<'button', ComponentPropsWithRef<typeof ButtonBase>> = ButtonBase;

export type Props = PropsWithoutAs<'button', PropsWithoutRef<ButtonProps>> & {
	/**
	 * Replaces the label with a loading indicator, and disables the onClick event
	 */
	loading?: boolean;
	/**
	 * Label when loading is true, for accessibility only
	 */
	loadingLabel?: string;
};

const LOADER_SIZE: Record<ButtonSize, number> = {
	small: 16,
	medium: 24,
	large: 32,
};
const LOADER_COLOR: Record<ButtonVariant, ColorName> = {
	primary: 'typography-lightPrimary',
	secondary: 'typography-secondary',
	secondaryEmphasis: 'primary-changeRed',
};

function ButtonInner(
	{
		children,
		variant,
		icon,
		iconPosition,
		mode,
		size,
		loading: loadingProp,
		loadingLabel,
		onClick: onClickProp,
		...rest
	}: Props,
	ref: ForwardedRef<HTMLButtonElement>,
): JSX.Element {
	const sizeResponsive = normalizeResponsiveValue(size || 'medium');
	const modeResponsive = normalizeResponsiveValue(mode);
	const loaderSizeResponsive = sizeResponsive.map((s) => (s ? LOADER_SIZE[s] : s)) as ResponsiveValue<number>;
	// we shouldn't be able to set a disabled button in a loading state
	const loading = !!loadingProp && !rest.disabled;
	if (!Children.toArray(children).length && process.env.NODE_ENV === 'development') {
		throw new Error('[a11y] missing label for Button');
	}
	const onClick: NonNullable<typeof onClickProp> = useCallback(
		(event) => {
			if (loading) return;
			onClickProp?.(event);
		},
		[loading, onClickProp],
	);
	return (
		<DecoratedButton
			variant={variant || 'primary'}
			onClick={onClick}
			{...rest}
			ref={ref}
			{...generateBreakpointAttributes('data-size', (idx) => sizeResponsive[idx] || 'medium')}
			{...generateBreakpointAttributes('data-mode', (idx) => {
				if (!Children.toArray(children).length) return 'icon';
				if (!icon) return 'label';
				return modeResponsive[idx] || 'icon+label';
			})}
			data-loading={!!loading}
			aria-live={loading ? 'assertive' : 'off'}
		>
			{icon ? (
				<ButtonWithIconWrapper icon={icon} iconPosition={iconPosition}>
					{children}
				</ButtonWithIconWrapper>
			) : (
				<Text sx={{ fontSize: 'inherit', lineHeight: 'inherit' }} data-label>
					{children}
				</Text>
			)}
			{loading && (
				<Flex
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Loader size={loaderSizeResponsive} color={LOADER_COLOR[variant || 'primary']} />
					<VisuallyHidden>{loadingLabel}</VisuallyHidden>
				</Flex>
			)}
		</DecoratedButton>
	);
}

/**
 * @doc $DOC:Button
 */
export const Button = forwardRef(ButtonInner);
