import type { JSX } from 'react';

import { css, Global } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';

export function GlobalStyle(): JSX.Element {
	return (
		<Global
			styles={css`
				${emotionNormalize}
			`}
		/>
	);
}
