import type { JSX, KeyboardEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';

import ReactDOM from 'react-dom';

import { useCurrentBreakpointValue } from '@change-corgi/design-system/responsive';
import type { ResponsiveArrayValue } from '@change-corgi/design-system/theme';

import { ModalOverlay } from './components/ModalOverlay';
import { genModalId } from './shared/genModalId';
import { getModalVariant } from './shared/getModalVariant';
import type {
	CloseModalWithResultsFunction,
	ModalOptions,
	ModalOptionsDefault,
	ModalOptionsProps,
	ModalProps,
} from './shared/types';

type Props<OPTIONS extends ModalOptions = ModalOptionsDefault, RESULT = undefined, STATE = undefined> = ModalProps<
	OPTIONS,
	RESULT,
	STATE
> &
	ModalOptionsProps<OPTIONS, RESULT, STATE>;

function BreakpointChangeWatcher({
	closeOnBreakpoints,
	onBreakpointChange,
}: {
	closeOnBreakpoints: ResponsiveArrayValue<boolean>;
	onBreakpointChange: (shouldClose: boolean) => void;
}): null {
	const shouldClose = useCurrentBreakpointValue(closeOnBreakpoints);

	useEffect(() => {
		if (shouldClose) {
			onBreakpointChange(shouldClose);
		}
	}, [shouldClose, onBreakpointChange]);

	return null;
}

// eslint-disable-next-line max-lines-per-function
export function Modal<OPTIONS extends ModalOptions = ModalOptionsDefault, RESULT = undefined, STATE = undefined>({
	variant,
	options,
	title,
	heading,
	body,
	onRequestClose,
	closeButton,
	closeOnClickOutside,
	closeOnEscape,
	closeOnBreakpoints,
	persistentState: initialPersistentState,
	onPersistentStateUpdate,
	stylesOverrides,
}: Props<OPTIONS, RESULT, STATE>): JSX.Element {
	// using a useState() hook to make sure it doesn't change within the modal
	const [persistentState] = useState(initialPersistentState);

	const [id] = useState(genModalId());

	const onKeyUp = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Escape' && closeOnEscape !== false) {
				onRequestClose?.({ source: 'escKey' });
			}
		},
		[onRequestClose, closeOnEscape],
	);

	const onClickOutside = useCallback(() => {
		if (closeOnClickOutside !== false) {
			onRequestClose?.({ source: 'clickOutside' });
		}
	}, [onRequestClose, closeOnClickOutside]);

	const onCloseButtonClick = useCallback(() => {
		onRequestClose?.({ source: 'closeButton' });
	}, [onRequestClose]);

	const closeModal = useCallback(
		(result: RESULT) => {
			onRequestClose?.({ source: 'result', result });
		},
		[onRequestClose],
	) as CloseModalWithResultsFunction<RESULT>;

	const onBreakpointChange = useCallback(
		(shouldClose: boolean) => {
			if (shouldClose) {
				onRequestClose?.({ source: 'breakpointChange' });
			}
		},
		[onRequestClose],
	);

	const ModalVariant = getModalVariant<OPTIONS, RESULT, STATE>(variant || 'default');

	return ReactDOM.createPortal(
		<ModalOverlay modalId={id} onClick={onClickOutside}>
			{/* using a separate component to avoid watching if closeOnBreakpoints is not set */}
			{closeOnBreakpoints && (
				<BreakpointChangeWatcher closeOnBreakpoints={closeOnBreakpoints} onBreakpointChange={onBreakpointChange} />
			)}
			{/* we are not setting role="dialog" due to issues with screen readers
			see also https://change.slack.com/archives/C0JA6L2FL/p1643927961986259 */}
			<ModalVariant
				tabIndex={-1}
				id={id}
				onKeyUp={onKeyUp}
				closeButton={closeButton}
				onCloseButtonClick={onCloseButtonClick}
				closeModal={closeModal}
				persistentState={persistentState}
				onPersistentStateUpdate={onPersistentStateUpdate}
				options={options}
				heading={heading}
				title={title}
				body={body}
				stylesOverrides={stylesOverrides}
			/>
		</ModalOverlay>,
		document.body,
	);
}
