import type { MandatoryResponsiveArrayValue, ResponsiveArrayValue } from '@change-corgi/design-system/theme';

export function createResponsiveArray<BASE_VALUE extends boolean | null>(
	values: ResponsiveArrayValue<BASE_VALUE> | MandatoryResponsiveArrayValue<BASE_VALUE>,
): BASE_VALUE extends null ? ResponsiveArrayValue<boolean> : MandatoryResponsiveArrayValue<boolean> {
	return values as BASE_VALUE extends null ? ResponsiveArrayValue<boolean> : MandatoryResponsiveArrayValue<boolean>;
}

export function invertResponsiveArray<BASE_VALUE extends boolean | null>(
	values: ResponsiveArrayValue<BASE_VALUE> | MandatoryResponsiveArrayValue<BASE_VALUE>,
): BASE_VALUE extends null ? ResponsiveArrayValue<boolean> : MandatoryResponsiveArrayValue<boolean> {
	return values.map((value) => {
		if (value === true) return false;
		if (value === false) return true;
		return value;
	}) as BASE_VALUE extends null ? ResponsiveArrayValue<boolean> : MandatoryResponsiveArrayValue<boolean>;
}

/**
 * @doc $DOC:ResponsiveHooks/BreakpointValuesGetter
 */
export function getBreakpointValues<BASE_VALUE extends boolean | null, TRUE_VALUE, FALSE_VALUE>(
	values: ResponsiveArrayValue<BASE_VALUE> | MandatoryResponsiveArrayValue<BASE_VALUE>,
	trueValue: TRUE_VALUE,
	falseValue: FALSE_VALUE,
): BASE_VALUE extends null
	? ResponsiveArrayValue<TRUE_VALUE | FALSE_VALUE>
	: MandatoryResponsiveArrayValue<TRUE_VALUE | FALSE_VALUE> {
	return values.map((value) => {
		if (value === true) return trueValue;
		if (value === false) return falseValue;
		return value;
	}) as BASE_VALUE extends null
		? ResponsiveArrayValue<TRUE_VALUE | FALSE_VALUE>
		: MandatoryResponsiveArrayValue<TRUE_VALUE | FALSE_VALUE>;
}

type GetBreakpointValuesFn<
	TRUE_VALUE,
	DEFAULT_TRUE_VALUE extends TRUE_VALUE,
	FALSE_VALUE,
	DEFAULT_FALSE_VALUE extends FALSE_VALUE,
> = {
	<BASE_VALUE extends boolean | null>(
		values: ResponsiveArrayValue<BASE_VALUE> | MandatoryResponsiveArrayValue<BASE_VALUE>,
	): BASE_VALUE extends null
		? ResponsiveArrayValue<DEFAULT_TRUE_VALUE | DEFAULT_FALSE_VALUE>
		: MandatoryResponsiveArrayValue<DEFAULT_TRUE_VALUE | DEFAULT_FALSE_VALUE>;
	<BASE_VALUE extends boolean | null, TV extends TRUE_VALUE>(
		values: ResponsiveArrayValue<BASE_VALUE> | MandatoryResponsiveArrayValue<BASE_VALUE>,
		trueValue: TV,
	): BASE_VALUE extends null
		? ResponsiveArrayValue<TV | DEFAULT_FALSE_VALUE>
		: MandatoryResponsiveArrayValue<TV | DEFAULT_FALSE_VALUE>;
	<BASE_VALUE extends boolean | null, TV extends TRUE_VALUE, FV extends FALSE_VALUE>(
		values: ResponsiveArrayValue<BASE_VALUE> | MandatoryResponsiveArrayValue<BASE_VALUE>,
		trueValue: TV,
		falseValue: FV,
	): BASE_VALUE extends null ? ResponsiveArrayValue<TV | FV> : MandatoryResponsiveArrayValue<TV | FV>;
};

export function createBreakpointValuesGetter<
	VALUE,
	DEFAULT_TRUE_VALUE extends VALUE = VALUE,
	DEFAULT_FALSE_VALUE extends VALUE = VALUE,
>(
	defaultTrueValue: DEFAULT_TRUE_VALUE,
	defaultFalseValue: DEFAULT_FALSE_VALUE,
): GetBreakpointValuesFn<VALUE, DEFAULT_TRUE_VALUE, VALUE, DEFAULT_FALSE_VALUE> {
	return ((
		values: ResponsiveArrayValue<boolean> | MandatoryResponsiveArrayValue<boolean>,
		trueValue: VALUE = defaultTrueValue,
		falseValue: VALUE = defaultFalseValue,
	) => getBreakpointValues(values, trueValue, falseValue)) as GetBreakpointValuesFn<
		VALUE,
		DEFAULT_TRUE_VALUE,
		VALUE,
		DEFAULT_FALSE_VALUE
	>;
}
