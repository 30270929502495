/*
 * This file is generated by icons/template.js
 */
const SvgQuote = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 40 37" {...props}>
		<path d="M17.922 36.62H.01V19.166c-.076-2.51.267-4.868 1.028-7.074.838-2.205 1.98-4.144 3.427-5.818a18.009 18.009 0 0 1 5.141-4.106A18.371 18.371 0 0 1 16.232 0v7.53c-2.894.988-4.874 2.471-5.94 4.448-1.067 1.902-1.6 4.26-1.6 7.073h9.23V36.62Zm22.078 0H22.089V19.166c-.076-2.51.266-4.868 1.028-7.074.837-2.205 1.98-4.144 3.427-5.818a18.007 18.007 0 0 1 5.14-4.106A18.37 18.37 0 0 1 38.31 0v7.53c-2.894.988-4.874 2.471-5.94 4.448-1.066 1.902-1.6 4.26-1.6 7.073H40V36.62Z" />
	</svg>
);
SvgQuote.displayName = 'iconQuote';
export default SvgQuote;
