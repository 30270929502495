import type { ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import type { Property } from 'csstype';
import type { ThemeUIStyleObject } from 'theme-ui';
import { Heading as HeadingBase } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import type { ColorName, HeadingVariant, ResponsiveValue } from '@change-corgi/design-system/theme';

import { getTextStyles } from './shared/styles';

export type HeadingSize = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'title' | 'subtitle';

type Props = Omit<ComponentPropsWithRef<typeof HeadingBase>, 'variant' | 'color' | 'backgroundColor' | 'bg'> & {
	ellipsis?: boolean;
	lineClamp?: ResponsiveValue<number>;
	breakWord?: boolean;
	variant?: HeadingVariant;
	size?: ResponsiveValue<HeadingSize>;
	color?: ResponsiveValue<ColorName | Property.Color | undefined>;
	backgroundColor?: ResponsiveValue<ColorName | Property.BackgroundColor | undefined>;
};

const HEADING_TAGS: HeadingSize[] = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

function getSize({ as, size }: Pick<Props, 'as' | 'size'>): ResponsiveValue<HeadingSize> {
	if (size) return size;
	if (typeof as !== 'string') return 'h1';
	if (HEADING_TAGS.includes(as as HeadingSize)) return as as HeadingSize;
	return 'h1';
}

export const SIZE_STYLES: Record<HeadingSize, { fontSize: string | number; lineHeight: string | number }> = {
	h1: {
		fontSize: '40px', // 40px is not in the guidelines
		lineHeight: 1.3,
	},
	h2: {
		fontSize: 32, // TODO: is it intentional or a coincidence that h2 is 32px and that size is in the fontSize scale?
		lineHeight: 1.2,
	},
	h3: {
		fontSize: 24, // TODO: is it intentional or a coincidence that h3 is 24px and that size is in the fontSize scale?
		lineHeight: 1.2,
	},
	h4: {
		fontSize: '18px', // 18px is not in the guidelines
		lineHeight: 1.2,
	},
	h5: {
		fontSize: 16, // TODO: is it intentional or a coincidence that h5 is 16px and that size is in the fontSize scale?
		lineHeight: 1.2,
	},
	h6: {
		fontSize: 14, // TODO: is it intentional or a coincidence that h6 is 14px and that size is in the fontSize scale?
		lineHeight: 1.2,
	},
	subtitle: {
		fontSize: 20,
		lineHeight: 1.6,
	},
	title: {
		fontSize: 28,
		lineHeight: 1.3,
	},
};

export function getTextSizeStyles(size: ResponsiveValue<HeadingSize> = 'h1'): ThemeUIStyleObject {
	return {
		fontSize: typeof size === 'string' ? SIZE_STYLES[size].fontSize : size.map((s) => s && SIZE_STYLES[s].fontSize),
		lineHeight:
			typeof size === 'string' ? SIZE_STYLES[size].lineHeight : size.map((s) => s && SIZE_STYLES[s].lineHeight),
	};
}

function HeadingInner(
	{ children, as, ellipsis, lineClamp, breakWord, color, variant, size, ...rest }: Props,
	ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
	return (
		<HeadingBase
			as={as || 'h1'}
			variant={`heading_${variant || 'default'}`}
			sx={{
				...getTextSizeStyles(getSize({ as, size })),
				...getTextStyles({ ellipsis, lineClamp, breakWord }),
			}}
			// there is a weird typing issue with color in theme-ui even though it does work with a responsive array
			// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
			color={color as any}
			{...rest}
			ref={ref}
		>
			{children}
		</HeadingBase>
	);
}

/**
 * @doc $DOC:Heading
 */
export const Heading = forwardRef(HeadingInner);
