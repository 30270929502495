import { isSsr } from '@change-corgi/core/ssr';

let idCount = 0;

export function genModalConfigId(): string {
	if (isSsr()) {
		throw new Error('Cannot render a modal server-side');
	}

	idCount += 1;
	return `modal_${idCount}`;
}
