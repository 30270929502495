/*
 * This file is generated by icons/template.js
 */
const SvgTwitch = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M7 2 3.428 5.572v12.857h4.286V22l3.572-3.572h2.857L20.572 12V2H7Zm12.142 9.286-2.857 2.857h-2.858l-2.5 2.5v-2.5H7.714V3.428h11.428v7.858Z"
		/>
		<path fill="currentColor" d="M17 5.928h-1.428v4.286H17V5.928ZM13.07 5.928h-1.427v4.286h1.428V5.928Z" />
	</svg>
);
SvgTwitch.displayName = 'iconTwitch';
export default SvgTwitch;
