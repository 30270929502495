export type BreakpointIndex = 0 | 1 | 2 | 3;
export type Breakpoints = [string, string, string];

export type BreakpointsValue<V> = [V, V, V, V]; // 1 more than the number of breakpoints

export type PartialBreakpointsValue<V, FIRST_VALUE = V> =
	| [FIRST_VALUE, V, V, V]
	| [FIRST_VALUE, V, V]
	| [FIRST_VALUE, V]
	| [FIRST_VALUE];

export type ResponsiveArrayValue<V> = PartialBreakpointsValue<V | null>;
export type ResponsiveValue<V> = V | ResponsiveArrayValue<V>;

// these are used to differentiate values for which a first "null" value is to be handled differently
// see normalizeResponsiveValue below for an example
export type MandatoryResponsiveArrayValue<V> = PartialBreakpointsValue<V | null, V>;
export type MandatoryResponsiveValue<V> = V | MandatoryResponsiveArrayValue<V>;

export const breakpoints: Breakpoints = ['768px', '1024px', '1280px'];

export const breakpointIndexes: [0, 1, 2, 3] = [0, 1, 2, 3];

function normalizeResponsiveValueToArray<V>(
	value: ResponsiveValue<V> | MandatoryResponsiveValue<V>,
): BreakpointsValue<V | null> {
	if (Array.isArray(value)) {
		return [
			...value.slice(0, breakpointIndexes.length),
			...(Array(Math.max(breakpointIndexes.length - value.length, 0)).fill(null) as PartialBreakpointsValue<V>),
		] as BreakpointsValue<V | null>;
	}

	return [value, value, value, value];
}

// normalizeResponsiveValue([1, null, 3]) returns a "BreakpointsValue<number>"
// normalizeResponsiveValue([null, 2, null, 4]) returns a "BreakpointsValue<number | null>", as the first value is "null"
export function normalizeResponsiveValue<V>(
	value: ResponsiveValue<V> | MandatoryResponsiveValue<V>,
): V extends null ? BreakpointsValue<V | null> : BreakpointsValue<V> {
	const array = normalizeResponsiveValueToArray(value);

	return array.reduce<unknown[]>((acc, val, idx) => {
		if (val === null && idx > 0) {
			// eslint-disable-next-line no-param-reassign
			acc[idx] = acc[idx - 1];
		} else {
			// eslint-disable-next-line no-param-reassign
			acc[idx] = val;
		}
		return acc;
	}, []) as V extends null ? BreakpointsValue<V | null> : BreakpointsValue<V>;
}

/**
 * this generates attributes for an element for each breakpoint
 *
 * @example
 * <span {...generateBreakpointAttributes('data-size', (idx) => sizes[idx])} />
 *
 * => <span data-size-0="small" data-size-1="small" data-size-2="medium" data-size-3="large" />
 */
export function generateBreakpointAttributes(
	prefix: string,
	value: (idx: 0 | 1 | 2 | 3) => string | number | boolean,
): Record<string, string | number | boolean> {
	return breakpointIndexes.reduce<Record<string, string | number | boolean>>((acc, idx) => {
		// eslint-disable-next-line no-param-reassign
		acc[`${prefix}-${idx}`] = value(idx);
		return acc;
	}, {});
}
