/** @jsxImportSource theme-ui */
import type { ComponentPropsWithRef, ForwardedRef, JSX, PropsWithChildren } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconExpandLess, iconExpandMore } from '@change-corgi/design-system/icons';
import { Box } from '@change-corgi/design-system/layout';
import { buttonResetStyles } from '@change-corgi/design-system/reset';

import { useContext as useAccordionContext } from '../hooks/context';

type Props = PropsWithChildren<ComponentPropsWithRef<typeof Box>>;

function AccordionSummaryInner({ children, ...rest }: Props, ref: ForwardedRef<HTMLElement>): JSX.Element {
	const { data, actions } = useAccordionContext();

	return (
		<Box {...rest} ref={ref}>
			<button
				id={data.id}
				aria-expanded={data.expanded}
				aria-controls={`${data.id}-details`}
				type="button"
				onClick={(e) => actions.toggle(e)}
				sx={{
					...buttonResetStyles,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					textAlign: 'left',
					fontWeight: data.expanded ? 'bold' : 'normal',
					width: '100%',
					p: 16,
				}}
			>
				{children}
				<Icon icon={data.expanded ? iconExpandLess : iconExpandMore} size={24} sx={{ ml: 24 }} />
			</button>
		</Box>
	);
}

/**
 * @doc $DOC:Accordion
 */
export const AccordionSummary = forwardRef(AccordionSummaryInner);
