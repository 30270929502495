import type { ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import { Link as LinkBase } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import { Link as RouterLink } from '@change-corgi/core/react/router';
import type { LinkVariant } from '@change-corgi/design-system/theme';
import type { ComponentWithAs, PropsWithoutAs } from '@change-corgi/design-system/types';

type PropsBase = Omit<ComponentPropsWithRef<typeof LinkBase>, 'variant'> & {
	variant?: LinkVariant;
};

const DecoratedLink: ComponentWithAs<'a', Props> = LinkBase;

type Props = Omit<PropsWithoutAs<typeof RouterLink, PropsBase>, 'innerRef'>;

function LinkInner({ children, variant, ...rest }: Props, ref: ForwardedRef<HTMLAnchorElement>): JSX.Element {
	return (
		<DecoratedLink variant={variant || 'primary'} as={RouterLink} ref={ref} {...rest}>
			{children}
		</DecoratedLink>
	);
}

/**
 * @doc $DOC:Link
 */
export const Link = forwardRef(LinkInner);
