/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { Theme as ThemeBase } from 'theme-ui';

import { badges } from './badges';
import { layout as boxLayout } from './box';
import type { Breakpoints } from './breakpoints';
import { breakpoints } from './breakpoints';
import { buttons } from './buttons';
import { cards } from './cards';
import type { Colors } from './colors';
import { colors } from './colors';
import { layout as containerLayout, sizes as containerSizes } from './container';
import type { Fonts } from './fonts';
import { fonts } from './fonts';
import { fontSizes } from './fontSizes';
import { fontWeights } from './fontWeights';
import { forms } from './forms';
import { heading } from './heading';
import { hr } from './hr';
import { images } from './images';
import { links } from './links';
import type { Radii } from './radii';
import { radii } from './radii';
import { space } from './space';
import { text } from './text';
import { zIndices } from './zIndices';
import type { ZIndices } from './zIndices';

export type Theme = Omit<ThemeBase, 'colors' | 'breakpoints' | 'fonts' | 'radii' | 'zIndices'> & {
	colors: Colors;
	breakpoints: Breakpoints;
	fonts: Fonts;
	radii: Radii;
	sizes: Record<string, any>;
	zIndices: ZIndices;
	// TODO add as needed
};

export const theme: Theme = {
	config: {
		useLocalStorage: false, // due to GDPR
		useCustomProperties: false, // due to legacy browser support
		useRootStyles: false,
	},
	badges,
	breakpoints,
	buttons,
	cards,
	colors,
	fonts,
	fontSizes,
	fontWeights,
	forms,
	images,
	layout: {
		...boxLayout,
		...containerLayout,
	},
	lineHeights: {
		heading: 'inherit',
	},
	links,
	radii,
	zIndices,
	styles: {
		...hr,
	},
	shadows: ['0px 10px 20px rgba(24, 25, 31, 0.1)'],
	sizes: {
		...containerSizes,
	},
	space,
	text: {
		...text,
		...heading,
	},
};
