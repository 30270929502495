import type { ComponentProps, ForwardedRef, JSX } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import type { Translate, TranslateHtml, TranslatePlural, TranslatePluralHtml } from '@change-corgi/core/react/i18n';
import { Link } from '@change-corgi/core/react/router';
import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { baseTabStyles } from '../shared/tabs/styles';

import type { TabLinksFunctionComponent } from './types';

type Props = Pick<ComponentProps<typeof Link>, 'to' | 'target' | 'onClick' | 'forceMode'> & {
	children:
		| string
		| ReadonlyArray<string | number>
		| React.ReactComponentElement<typeof Translate>
		| React.ReactComponentElement<typeof TranslateHtml>
		| React.ReactComponentElement<typeof TranslatePlural>
		| React.ReactComponentElement<typeof TranslatePluralHtml>;
	name: string;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	__internal_selected?: boolean;
};

function TabLinkInner(
	// eslint-disable-next-line @typescript-eslint/naming-convention
	{ children, name, __internal_selected, ...rest }: Props,
	ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
	return (
		<Box
			as="li"
			sx={{
				...baseTabStyles(__internal_selected),
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'& > a': {
					color: 'inherit',
					textDecoration: 'none',
				},
			}}
			ref={ref}
		>
			<Link {...rest}>
				<Text>{children}</Text>
			</Link>
		</Box>
	);
}

/**
 * @doc $DOC:TabLinks
 */
const TabLink: TabLinksFunctionComponent<Props> = forwardRef(TabLinkInner);
TabLink.tabsComponentName = 'TabLink';
export { TabLink };
