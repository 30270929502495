import type { ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import { Badge as BadgeBase } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import { Icon } from '@change-corgi/design-system/components/icon';
import type { IconComponent } from '@change-corgi/design-system/icons';
import { Text } from '@change-corgi/design-system/typography';

type IconPosition = 'start' | 'end';

type Props = ComponentPropsWithRef<typeof BadgeBase> & {
	icon?: IconComponent;
	iconPosition?: IconPosition;
};

function BadgeInner(
	{ bg, children, icon, iconPosition, sx, ...rest }: Props,
	ref: ForwardedRef<HTMLImageElement>,
): JSX.Element {
	return (
		<BadgeBase
			px={16}
			ref={ref}
			sx={{
				display: 'flex',
				alignItems: 'center',
				borderRadius: 'initial',
				fontWeight: 'initial',
				height: 40,
				flexDirection: iconPosition === 'end' ? 'row-reverse' : 'row',
				justifyContent: iconPosition === 'end' ? 'space-between' : 'start',
				...sx, // only required for storybook to take overrides into account
			}}
			{...rest}
		>
			{icon && <Icon size="m" icon={icon} mr={8} />}
			<Text size="caption" sx={{ fontWeight: 'bold' }} ellipsis>
				{children}
			</Text>
		</BadgeBase>
	);
}

/**
 * @doc $DOC:Badge
 */
export const Badge = forwardRef(BadgeInner);
