/*
 * This file is generated by icons/template.js
 */
const SvgCheck = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
	</svg>
);
SvgCheck.displayName = 'iconCheck';
export default SvgCheck;
