import { useMemo } from 'react';

import { useModalsContext } from './context';
import { genModalConfigId } from './shared/genModalConfigId';
import type { CloseParams, Modal, ModalConfig, ModalOptions, ModalOptionsDefault } from './shared/types';

type OpenModalResult<RESULT = undefined> = {
	/**
	 * Returns a promise that resolves with the result of the modal, or `undefined` if that does not apply
	 */
	waitForResult: () => Promise<RESULT | undefined>;
	/**
	 * Returns a promise that resolves with the close status of the modal, including what actually closed the modal
	 */
	waitForClosed: () => Promise<CloseParams<RESULT>>;
};

export type ModalHookResult<OPTIONS extends ModalOptions = ModalOptionsDefault, RESULT = undefined> = (
	options: OPTIONS,
) => OpenModalResult<RESULT>;

export type ModalHook<OPTIONS extends ModalOptions = ModalOptionsDefault, RESULT = undefined, STATE = undefined> = (
	modal: Modal<OPTIONS, RESULT, STATE>,
) => ModalHookResult<OPTIONS, RESULT>;

/**
 * @deprecated Prefer using createModalHook() instead of this, as it allows easier testing/mocking
 *
 * @doc $DOC:Modal
 */
// eslint-disable-next-line max-lines-per-function
export function useModal<OPTIONS extends ModalOptions = ModalOptionsDefault, RESULT = undefined, STATE = undefined>(
	modal: Modal<OPTIONS, RESULT, STATE>,
	{ killOpenModal }: { killOpenModal?: boolean } = {},
): ModalHookResult<OPTIONS, RESULT> {
	const { setModal, history } = useModalsContext();

	// eslint-disable-next-line max-lines-per-function
	return useMemo(() => {
		// not using a state hook for these as we don't want to recreate the function
		let open = false;
		let persistentState: STATE | undefined;

		// eslint-disable-next-line max-lines-per-function
		return (options: OPTIONS) => {
			if (open) throw new Error(`Modal ${modal.displayName || modal.name} is already open`);

			const id = genModalConfigId();

			let resolveResult: (result: RESULT | undefined) => void;
			// eslint-disable-next-line promise/avoid-new, @typescript-eslint/no-explicit-any
			const resultPromise: Promise<RESULT | undefined> = new Promise((resolve) => {
				resolveResult = resolve;
			});

			// eslint-disable-next-line promise/avoid-new, @typescript-eslint/no-explicit-any
			const closePromise = new Promise<any>((resolve) => {
				open = true;

				const newModal = (() => {
					const unlistenHistory = !history
						? undefined
						: history.listen(() => {
								newModal.onClosed?.({ source: 'locationChange' });
							});

					const onClosed: ModalConfig<OPTIONS, RESULT, STATE>['onClosed'] = (context) => {
						unlistenHistory && unlistenHistory();

						// do not call setModal when killing because we'd already be in a setModal()(see below)
						if (context.source !== 'killed') {
							setModal(() => undefined);
						}

						open = false;

						resolve(context);
						resolveResult(context.source === 'result' ? context.result : undefined);
					};

					return {
						id,
						modal,
						options,
						onClosed,
						persistentState,
						onPersistentStateUpdate: (newState) => {
							persistentState = newState;
						},
					} as ModalConfig<OPTIONS, RESULT, STATE>;
				})();

				setModal((currentModal) => {
					if (currentModal) {
						if (killOpenModal) {
							currentModal.onClosed?.({ source: 'killed' });
						} else {
							throw new Error(
								`Cannot open modal ${modal.displayName || modal.name}: another modal (${
									currentModal.modal.displayName || currentModal.modal.name
								}) is already open`,
							);
						}
					}
					return newModal;
				});
			});

			return {
				waitForResult: async () => resultPromise,
				// eslint-disable-next-line @typescript-eslint/no-unsafe-return
				waitForClosed: async () => closePromise,
			};
		};
	}, [modal, setModal, history, killOpenModal]);
}
