import type { MandatoryResponsiveArrayValue } from '@change-corgi/design-system/theme';
import { normalizeResponsiveValue } from '@change-corgi/design-system/theme';

import { useCurrentBreakpoint } from './useCurrentBreakpoint';

// useCurrentBreakpointValue([1, null, 3]) returns a "number"
// useCurrentBreakpointValue([null, 2, null, 4]) returns a "number | null", as the first value is "null"
/**
 * @doc $DOC:ResponsiveHooks/CurrentBreakpointValueHook
 */
export function useCurrentBreakpointValue<V>(values: MandatoryResponsiveArrayValue<V>): V {
	const breakpoint = useCurrentBreakpoint();

	return normalizeResponsiveValue(values)[breakpoint] as V;
}
