import type { ComponentPropsWithRef, ForwardedRef, JSX, PropsWithChildren, SyntheticEvent } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import { useOptionalId } from '@change-corgi/design-system/core';
import { Box } from '@change-corgi/design-system/layout';

import { Provider as AccordionProvider } from './hooks/context';
import { useAccordion } from './hooks/useAccordion';

type AccordionProps = {
	/**
	 * Reference for the `Accordion` component.
	 * This will be used to infer the attributes
	 * for the `AccordionSummary` and `AccordionDetails` component
	 * to ensure proper accessibility
	 */
	id?: string;
	/**
	 * Default `expanded` state of the `Accordion`
	 */
	defaultExpanded?: boolean;
	/**
	 * Sets the state of the `Accordion`. Setting this value
	 * will enable the `controlled` component mode.
	 */
	expanded?: boolean;
	/**
	 * Callback fired when the `expanded` state changes.
	 * Additionally allows control over the `expanded` state
	 * when in `controlled` component mode.
	 */
	onExpandedChange?: (e: SyntheticEvent, expanded: boolean) => void;
	/**
	 * Controls whether to include the `AccordionDetails` content in the dom.
	 * The default behavior is to have the content present in the `DOM`
	 * so it can be included for SEO purposes.
	 */
	renderDetailsOnlyWhenExpanded?: boolean;
};

type RootProps = Omit<ComponentPropsWithRef<typeof Box>, 'id'>;

type Props = PropsWithChildren<AccordionProps & RootProps>;

function AccordionInner(
	{ children, defaultExpanded, expanded, id: idProp, onExpandedChange, renderDetailsOnlyWhenExpanded, ...rest }: Props,
	ref: ForwardedRef<HTMLElement>,
): JSX.Element {
	const id = useOptionalId(idProp);
	const { data, actions } = useAccordion({
		defaultExpanded,
		expanded,
		id,
		onExpandedChange,
		renderDetailsOnlyWhenExpanded,
	});

	return (
		<AccordionProvider data={data} actions={actions}>
			<Box {...rest} ref={ref}>
				{children}
			</Box>
		</AccordionProvider>
	);
}

/**
 * @doc $DOC:Accordion
 *
 * https://www.w3.org/WAI/ARIA/apg/example-index/accordion/accordion.html
 */
export const Accordion = forwardRef(AccordionInner);
