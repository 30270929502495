import type { ComponentProps, MouseEvent } from 'react';
import { useCallback, useState } from 'react';

import type { Accordion } from '../Accordion';

type UseAccordionReturnType = {
	data: { expanded: boolean; id: string; renderDetailsOnlyWhenExpanded?: boolean };
	actions: { toggle: (e: MouseEvent<HTMLButtonElement>) => void };
};

type UseAccordionArgs = Pick<
	ComponentProps<typeof Accordion>,
	'defaultExpanded' | 'expanded' | 'onExpandedChange' | 'renderDetailsOnlyWhenExpanded'
> & {
	id: string;
};
export function useAccordion({
	defaultExpanded,
	expanded,
	id,
	onExpandedChange,
	renderDetailsOnlyWhenExpanded,
}: UseAccordionArgs): UseAccordionReturnType {
	const isControlled = expanded !== undefined;
	const [isExpanded, setIsExpanded] = useState(!!defaultExpanded);
	const expandedValue = isControlled ? expanded : isExpanded;

	const toggle = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			if (!isControlled) setIsExpanded(!isExpanded);

			onExpandedChange?.(e, !expandedValue);
		},
		[expandedValue, isControlled, isExpanded, onExpandedChange],
	);

	const data = { expanded: expandedValue, id, renderDetailsOnlyWhenExpanded };
	const actions = { toggle };

	return { data, actions };
}
