import type { ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import type { Property } from 'csstype';
import { Container as ContainerBase } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import type { ColorName, ContainerVariant, ResponsiveValue } from '@change-corgi/design-system/theme';

export type ContainerProps = Omit<
	ComponentPropsWithRef<typeof ContainerBase>,
	'variant' | 'color' | 'backgroundColor' | 'bg'
> & {
	variant?: ContainerVariant;
	color?: ResponsiveValue<ColorName | Property.Color | undefined>;
	backgroundColor?: ResponsiveValue<ColorName | Property.BackgroundColor | undefined>;
};

function ContainerInner(
	{ children, variant, color, ...rest }: ContainerProps,
	ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
	return (
		<ContainerBase
			variant={variant ? `layout.container_${variant}` : 'layout.container'}
			// there is a weird typing issue with color in theme-ui even though it does work with a responsive array
			// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
			color={color as any}
			{...rest}
			ref={ref}
		>
			{children}
		</ContainerBase>
	);
}

/**
 * @doc $DOC:Container
 */
export const Container = forwardRef(ContainerInner);
