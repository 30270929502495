import type { ForwardedRef, JSX } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import type { ButtonLinkProps, ButtonMode } from '@change-corgi/design-system/components/actions';
import { ButtonLink } from '@change-corgi/design-system/components/actions';
import type { ResponsiveValue } from '@change-corgi/design-system/theme';
import { normalizeResponsiveValue } from '@change-corgi/design-system/theme';

import type { Channel } from '../shared/types';

import { getSocialColor, getSocialIcon, isButtonFillable } from './shared/mapping';

type Props = Omit<ButtonLinkProps, 'variant' | 'icon' | 'iconPosition' | 'mode'> & {
	channel: Channel;
	filled?: boolean;
	iconOnly?: ResponsiveValue<boolean>;
};

function SocialButtonLinkInner(
	{ children, channel, filled, iconOnly, ...rest }: Props,
	ref: ForwardedRef<HTMLAnchorElement>,
): JSX.Element {
	const iconOnlyResponsive = normalizeResponsiveValue(iconOnly);
	const mode = iconOnlyResponsive.map((value): ButtonMode | undefined | null => {
		if (value === true) return 'icon';
		if (value === false) return 'icon+label';
		return value;
	}) as ResponsiveValue<ButtonMode>;
	return (
		<ButtonLink
			variant={filled && isButtonFillable(channel) ? 'primary' : 'secondary'}
			icon={getSocialIcon(channel)}
			color={getSocialColor(channel)}
			mode={mode}
			{...rest}
			ref={ref}
		>
			{children}
		</ButtonLink>
	);
}

/**
 * @doc $DOC:SocialButtonLink
 */
export const SocialButtonLink = forwardRef(SocialButtonLinkInner);
