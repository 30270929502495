/*
 * This file is generated by icons/template.js
 */
const SvgNavigateNext = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
	</svg>
);
SvgNavigateNext.displayName = 'iconNavigateNext';
export default SvgNavigateNext;
