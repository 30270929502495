import type { ComponentProps, ForwardedRef, JSX } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import { useOptionalId } from '@change-corgi/design-system/core';

import { BareTextarea } from './BareTextarea';
import { InputWrapper } from './components';

type Props = Omit<
	ComponentProps<typeof InputWrapper> & ComponentProps<typeof BareTextarea>,
	'errorId' | 'helperTextId'
>;

function TextareaInner(
	{ caption, error, helperText, id: idProp, label, hideLabel, size, ...rest }: Props,
	ref: ForwardedRef<HTMLTextAreaElement>,
): JSX.Element {
	const id = useOptionalId(idProp);
	const errorId = `${id}___error`;
	const helperTextId = `${id}___helpertext`;

	return (
		<InputWrapper
			caption={caption}
			error={error}
			errorId={errorId}
			helperText={helperText}
			helperTextId={helperTextId}
			hideLabel={hideLabel}
			label={label}
			size={size}
		>
			<BareTextarea
				aria-describedby={`${helperTextId} ${errorId}`}
				error={error}
				id={id}
				ref={ref}
				size={size}
				{...rest}
			/>
		</InputWrapper>
	);
}

/**
 * @doc $DOC:Textarea
 */
export const Textarea = forwardRef(TextareaInner);
