/** @jsxImportSource theme-ui */
import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Box } from '@change-corgi/design-system/layout';
import { useTheme } from '@change-corgi/design-system/theme';
import { Text } from '@change-corgi/design-system/typography';

type Props<OPTION> = {
	id: string;
	labelId: string;
	listboxRef: React.RefObject<HTMLUListElement>;
	activeIndex: number;
	highlightedIndex: number;
	options: readonly OPTION[];
	optionToString: (option: OPTION) => string;
	renderOption?: (option: OPTION) => JSX.Element;
	handleSelect: (event: React.MouseEvent<HTMLLIElement>, index: number) => void;
	handleMouseEnter: (index: number) => void;
	handleMouseDown: (event: React.MouseEvent<HTMLLIElement>) => void;
};

export function OptionsList<OPTION>({
	id,
	labelId,
	listboxRef,
	activeIndex,
	highlightedIndex,
	options,
	optionToString,
	renderOption,
	handleSelect,
	handleMouseEnter,
	handleMouseDown,
}: Props<OPTION>): JSX.Element {
	const theme = useTheme();

	return (
		<Box
			id={`${id}-popover`}
			sx={{
				position: 'absolute',
				top: '45px',
				maxHeight: '300px',
				overflowY: 'auto',
				width: '100%',
				backgroundColor: 'white',
				border: '1px solid',
				borderColor: 'primary-black',
				borderRadius: '8px',
			}}
		>
			{options.length === 0 ? (
				<Text
					as="div"
					sx={{
						borderBottom: '1px solid',
						borderTop: '1px solid',
						borderColor: 'transparent',
						paddingX: 12,
						paddingY: 14,
					}}
				>
					<Translate value="design-system.combobox.no_options_label" />
				</Text>
			) : (
				<ul
					id={`${id}-listbox`}
					role="listbox"
					aria-labelledby={labelId}
					ref={listboxRef}
					sx={{
						listStyle: 'none',
						padding: 0,
						marginX: 0,
						/* eslint-disable @typescript-eslint/naming-convention */
						'li:first-of-type': { borderTopColor: 'transparent' },
						'li:last-of-type': { borderBottomColor: 'transparent' },
						/* eslint-enable @typescript-eslint/naming-convention */
						fontFamily: theme.fonts.body,
					}}
				>
					{options.map((option, index) => (
						// keydown event is managed on the `input` element, so we don't need this additional event
						// eslint-disable-next-line jsx-a11y/click-events-have-key-events
						<li
							id={`${id}-${index}`}
							role="option"
							aria-selected={activeIndex === index}
							data-highlighted={highlightedIndex === index}
							sx={{
								borderBottom: '1px solid',
								borderTop: '1px solid',
								borderColor: 'transparent',
								/* eslint-disable @typescript-eslint/naming-convention */
								'&[data-highlighted=true]': {
									backgroundColor: 'primary-greyBackground',
								},
								// selected should always come after highlighted!!
								'&[aria-selected=true]': {
									backgroundColor: 'neutral-grey100',
								},
								/* eslint-enable @typescript-eslint/naming-convention */
								paddingX: 12,
								paddingY: 14,
								marginX: 0,
								cursor: 'pointer',
							}}
							key={optionToString(option)}
							onClick={(e) => handleSelect(e, index)}
							onMouseEnter={() => handleMouseEnter(index)}
							onMouseDown={handleMouseDown}
						>
							{renderOption ? renderOption(option) : optionToString(option)}
						</li>
					))}
				</ul>
			)}
		</Box>
	);
}
