import type { MutableRefObject } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';

type RefObject<T> = MutableRefObject<T>;

type UseIntersectionObserverReturn = IntersectionObserverEntry | null;

type IntersectionObserverOptions = { root: RefObject<Element | Document | undefined | null>; threshold: number };
export function useIntersectionObserver({
	root,
	threshold,
}: IntersectionObserverOptions): UseIntersectionObserverReturn {
	const observerRef = useRef<IntersectionObserver | null>(null);
	const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);

	const isIntersectionObserverSupported = useMemo(
		() => typeof window !== 'undefined' && 'IntersectionObserver' in window,
		[],
	);

	const disconnect = () => {
		if (!observerRef.current) return;

		observerRef.current.disconnect();
		observerRef.current = null;
	};

	useEffect(() => {
		if (!isIntersectionObserverSupported || !root.current) return;

		const handleIntersection: IntersectionObserverCallback = (observerEntries) => {
			observerEntries.forEach((observerEntry) => {
				if (observerEntry.isIntersecting) {
					setEntry(observerEntry);
				}
			});
		};

		const observer = new IntersectionObserver(handleIntersection, { root: root.current, threshold });

		Array.from(root.current.children).forEach((child) => {
			observer.observe(child);
		});

		observerRef.current = observer;

		// eslint-disable-next-line consistent-return
		return disconnect;
	}, [isIntersectionObserverSupported, root, threshold]);

	return entry;
}
