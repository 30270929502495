/*
 * This file is generated by icons/template.js
 */
const SvgWhatsapp = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
		<g clipPath="url(#whatsapp_svg__a)">
			<path
				fill="currentColor"
				stroke="currentColor"
				strokeWidth={0.5}
				d="M19.154 5.76A9.344 9.344 0 0 0 12.496 3C7.305 3 3.08 7.224 3.08 12.415c0 1.658.432 3.279 1.255 4.708L3 22l4.992-1.31a9.386 9.386 0 0 0 4.5 1.145h.004c5.187 0 9.504-4.225 9.504-9.416 0-2.515-1.069-4.877-2.846-6.658Zm-6.658 14.488a7.812 7.812 0 0 1-3.987-1.09l-.284-.17-2.96.777.789-2.888-.187-.297a7.8 7.8 0 0 1-1.196-4.165c0-4.313 3.512-7.825 7.829-7.825 2.09 0 4.055.815 5.53 2.295 1.476 1.48 2.384 3.444 2.38 5.534 0 4.318-3.601 7.83-7.914 7.83Zm4.292-5.86c-.233-.12-1.391-.688-1.608-.764-.216-.08-.373-.119-.53.119-.157.237-.606.763-.746.924-.136.157-.276.178-.51.06-1.382-.692-2.29-1.235-3.201-2.8-.242-.415.242-.386.691-1.285.076-.156.038-.292-.021-.411-.06-.119-.53-1.277-.725-1.747-.191-.458-.386-.395-.53-.403-.136-.009-.293-.009-.45-.009a.872.872 0 0 0-.628.293c-.216.237-.822.806-.822 1.964 0 1.157.844 2.277.958 2.434.119.157 1.658 2.532 4.02 3.554 1.494.645 2.079.7 2.825.59.454-.068 1.391-.569 1.586-1.12.195-.552.195-1.022.136-1.12-.055-.106-.212-.165-.445-.28Z"
			/>
		</g>
		<defs>
			<clipPath id="whatsapp_svg__a">
				<path fill="transparent" d="M0 0h24v24H0z" />
			</clipPath>
		</defs>
	</svg>
);
SvgWhatsapp.displayName = 'iconWhatsapp';
export default SvgWhatsapp;
