import type { ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import type { Property } from 'csstype';
import { Flex as FlexBase } from 'theme-ui';

import { forwardRef } from '@change-corgi/core/react/core';
import type { BoxVariant, ColorName, ResponsiveValue } from '@change-corgi/design-system/theme';

export type FlexProps = Omit<ComponentPropsWithRef<typeof FlexBase>, 'variant' | 'color' | 'backgroundColor' | 'bg'> & {
	variant?: BoxVariant;
	color?: ResponsiveValue<ColorName | Property.Color | undefined>;
	backgroundColor?: ResponsiveValue<ColorName | Property.BackgroundColor | undefined>;
};

function FlexInner({ children, variant, color, ...rest }: FlexProps, ref: ForwardedRef<HTMLElement>): JSX.Element {
	return (
		<FlexBase
			variant={variant ? `layout.box_${variant}` : undefined}
			// there is a weird typing issue with color in theme-ui even though it does work with a responsive array
			// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
			color={color as any}
			{...rest}
			ref={ref}
		>
			{children}
		</FlexBase>
	);
}

/**
 * @doc $DOC:Flex
 */
export const Flex = forwardRef(FlexInner);
