import { createModal } from './factory';
import type { Modal, ModalFactoryOptions, ModalOptions, ModalOptionsDefault } from './shared/types';
import type { ModalHookResult } from './useModal';
import { useModal } from './useModal';

export type DecoratedModalHook<
	OPTIONS extends ModalOptions = ModalOptionsDefault,
	RESULT = undefined,
	STATE = undefined,
> = (() => ModalHookResult<OPTIONS, RESULT>) & {
	/**
	 * internal use only
	 */
	// eslint-disable-next-line @typescript-eslint/naming-convention
	__internal__Modal: Modal<OPTIONS, RESULT, STATE>;
};

/**
 * @doc $DOC:Modal
 */
export function createModalHook<
	OPTIONS extends ModalOptions = ModalOptionsDefault,
	RESULT = undefined,
	STATE = undefined,
>({
	killOpenModal,
	...options
}: ModalFactoryOptions<OPTIONS, RESULT, STATE>): DecoratedModalHook<OPTIONS, RESULT, STATE> {
	// using deprecated createModal on purpose, as it's only deprecated for external use
	// eslint-disable-next-line deprecation/deprecation
	const Modal = createModal(options);
	const hook: DecoratedModalHook<OPTIONS, RESULT, STATE> = function () {
		// using deprecated useModal on purpose, as it's only deprecated for external use
		// eslint-disable-next-line react-hooks/rules-of-hooks, deprecation/deprecation
		return useModal(Modal, { killOpenModal });
	};
	try {
		// doing this in a try/catch in case the property is readonly in some browsers
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
		(hook as any).name = `use${options.name}`;
	} catch (e) {
		// ignore error
	}
	hook.__internal__Modal = Modal;
	return hook;
}
