import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Button } from '@change-corgi/design-system/components/actions';
import { iconNavigateBefore, iconNavigateNext } from '@change-corgi/design-system/icons';
import { Box } from '@change-corgi/design-system/layout';
import { useBreakpoints } from '@change-corgi/design-system/theme';

type ButtonProps = { onClick: () => void; disabled: boolean };
type Props = { previous: ButtonProps; next: ButtonProps };
export function NavigationButtons({ previous, next }: Props): JSX.Element {
	const breakpoints = useBreakpoints();

	return (
		<Box sx={{ display: 'contents', position: 'relative' }}>
			<Button
				data-testid="carousel-previous-button"
				aria-controls="carousel-items"
				onClick={previous.onClick}
				disabled={previous.disabled}
				size="small"
				variant="secondary"
				icon={iconNavigateBefore}
				iconPosition="start"
				mode="icon"
				/* eslint-disable @typescript-eslint/naming-convention */
				sx={{
					boxShadow: '0px 3px 3px 0px rgba(0,0,0,0.12)',
					borderRadius: '50% !important',
					gridColumn: 1,
					gridRow: 1,
					left: -1000,
					placeSelf: 'center',
					position: 'absolute',
					zIndex: 1,
					'&:focus': {
						position: 'static',
					},
					[`@media screen and (min-width: ${breakpoints[0]})`]: {
						position: 'static',
					},
				}}
				/* eslint-enable @typescript-eslint/naming-convention */
			>
				<Translate value="design-system.carousel.previous-label" />
			</Button>
			<Button
				data-testid="carousel-next-button"
				aria-controls="carousel-items"
				onClick={next.onClick}
				disabled={next.disabled}
				size="small"
				variant="secondary"
				icon={iconNavigateNext}
				iconPosition="start"
				mode="icon"
				/* eslint-disable @typescript-eslint/naming-convention */
				sx={{
					boxShadow: '0px 3px 3px 0px rgba(0,0,0,0.12)',
					borderRadius: '50% !important',
					gridColumn: 3,
					gridRow: 1,
					placeSelf: 'center',
					position: 'absolute',
					right: -1000,
					zIndex: 1,
					'&:focus': {
						position: 'static',
					},
					[`@media screen and (min-width: ${breakpoints[0]})`]: {
						position: 'static',
					},
				}}
				/* eslint-enable @typescript-eslint/naming-convention */
			>
				<Translate value="design-system.carousel.next-label" />
			</Button>
		</Box>
	);
}
