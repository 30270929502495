/*
 * This file is generated by icons/template.js
 */
const SvgLinkedIn = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" {...props}>
		<g fill="currentColor" clipPath="url(#linkedIn_svg__a)">
			<path d="M21.6 21.6v-7.032c0-3.456-.744-6.096-4.776-6.096-1.944 0-3.24 1.056-3.768 2.064h-.048V8.784H9.192V21.6h3.984v-6.36c0-1.68.312-3.288 2.376-3.288 2.04 0 2.064 1.896 2.064 3.384v6.24H21.6v.024ZM2.712 8.784h3.984V21.6H2.712V8.784ZM4.704 2.4A2.305 2.305 0 0 0 2.4 4.704c0 1.272 1.032 2.328 2.304 2.328 1.272 0 2.304-1.056 2.304-2.328A2.305 2.305 0 0 0 4.704 2.4Z" />
		</g>
		<defs>
			<clipPath id="linkedIn_svg__a">
				<path fill="#fff" d="M0 0h24v24H0z" />
			</clipPath>
		</defs>
	</svg>
);
SvgLinkedIn.displayName = 'iconLinkedIn';
export default SvgLinkedIn;
