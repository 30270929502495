import type { ThemeUIStyleObject } from 'theme-ui';

export type ContainerVariant = 'default' | 'small';

const containerDefault: ThemeUIStyleObject = {
	maxWidth: 1294,
	width: [null, '740px', '960px', '1294px'],
};

export const layout: Record<'container' | `container_${ContainerVariant}`, ThemeUIStyleObject> = {
	container: containerDefault,
	container_default: containerDefault,
	container_small: {
		maxWidth: 960,
		width: [null, '740px', '960px'],
	},
};

// This is included so that theme-ui doesn't set an invalid max-width: 'container' property on the container element.
// It doesn't seem to be setting max-width: 960px as it should, though, so it's set above in the default variant styles.
export const sizes: Record<'container' | `container_${ContainerVariant}`, ThemeUIStyleObject> = {
	container: {
		maxWidth: 1294,
	},
	container_default: {
		maxWidth: 1294,
	},
	container_small: {
		maxWidth: 960,
	},
};
