import { useCallback, useRef, useState } from 'react';

export function useControlled<V>(controlledValue: V, defaultValue: V): readonly [V, (value: V) => void] {
	const { current: controlled } = useRef(controlledValue !== undefined);

	const [uncontrolledValue, setUncontrolledValue] = useState(defaultValue);

	const setValueIfUncontrolled = useCallback((newValue: V) => {
		if (!controlled) {
			setUncontrolledValue(newValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const value = controlled ? controlledValue : uncontrolledValue;

	return [value, setValueIfUncontrolled] as const;
}
