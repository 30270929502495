/*
 * This file is generated by icons/template.js
 */
const SvgOutlinedFlag = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
		<path d="m14 6-1-2H5v17h2v-7h5l1 2h7V6h-6zm4 8h-4l-1-2H7V6h5l1 2h5v6z" />
	</svg>
);
SvgOutlinedFlag.displayName = 'iconOutlinedFlag';
export default SvgOutlinedFlag;
